import React from 'react'
import './ScoreboardEntry.scss'
import Grid from '@material-ui/core/Grid/Grid'
import {formatPoints} from '../../helper/helperfunctions'


const ScoreboardEntry = props => {
  const {position, player, numberOfGuesses, numberOfLocations, score} = props
  return (
    <>
      <div className='player'>
        <span className='positionIndicator'>{position}.</span>
        <img src={`data:image/jpeg;base64,${player.picture}`} width={40} height={40} alt=''/>
        <Grid container spacing={4}>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            <span className='playerUsername'>{player.username}</span>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
          >
            <span>{numberOfGuesses}/{numberOfLocations}</span>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
          >
            <span>{formatPoints(score)}</span>
          </Grid>
        </Grid>
      </div>
      <div className='playerSeparator'/>
    </>
  )
}

export default ScoreboardEntry