import React from 'react'
import CardContent from '@material-ui/core/CardContent/CardContent'
import Card from '@material-ui/core/Card/Card'
import './PlayersOverview.scss'
import Player from './Player'


const PlayersOverview = ({players, lobby}) => {
  if (!players) {
    return (
      <Card className='playersOverviewCard shadowBox transparentBackground'>
        <CardContent/>
      </Card>
    )
  }
  return (
    <Card className='playersOverviewCard shadowBox transparentBackground'>
      <CardContent>
        {
          players.map(player => (
            <Player
              player={player}
              isLobbyLeader={player.userId === lobby.lobbyLeader.userId}
              />
          ))
        }
      </CardContent>
    </Card>
  )
}

export default PlayersOverview