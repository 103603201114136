import appConf from '../conf/app-conf'

import axios from 'axios'

const retrieveLobby = lobbyId =>
  axios
    .get(`${appConf.backendUrl}/api/v1/lobby/join/${lobbyId}`)
    .then(res => res.data)

const postNewLobby = (background) =>
  axios
    .post(`${appConf.backendUrl}/api/v1/lobby/new`, {
      background
    })
    .then(res => res.data)

const retrieveLobbiesOfUser = () =>
  axios
    .get(`${appConf.backendUrl}/api/v1/lobby/user/me`)
    .then(res => res.data)

const leaveLobby = lobbyId =>
  axios
    .get(`${appConf.backendUrl}/api/v1/lobby/leave/${lobbyId}`)
    .then(res => res.data)

const updateGameSettings = (lobbyId, gameSettings) =>
  axios
    .put(`${appConf.backendUrl}/api/v1/lobby/update/${lobbyId}`, gameSettings)
    .then(res => res.data)


export default {retrieveLobby, postNewLobby, retrieveLobbiesOfUser, leaveLobby, updateGameSettings}
