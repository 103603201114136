import React from 'react'
import RoundScoreboardEntry from './RoundScoreboardEntry'
import RoundScoreboardEntryCountry from './RoundScoreboardEntryCountry'
import {getCountryInfo} from '../../helper/helperfunctions'


const RoundScoreboardCountry = ({
                           roundNumber, players, guesses, playersToReadyRound, playersToCountryCorrect, playersToScores
                         }) => {
  return (
    <>
      {
        [...players]
          .filter(player => playersToCountryCorrect[player.userId] != null && playersToCountryCorrect[player.userId][roundNumber - 1] != null)
          .sort((a, b) => {
            if (playersToCountryCorrect[a.userId][roundNumber - 1] && !playersToCountryCorrect[b.userId][roundNumber - 1]) {
              return -1
            }
            if (!playersToCountryCorrect[a.userId][roundNumber - 1] && playersToCountryCorrect[b.userId][roundNumber - 1]) {
              return 1
            }
            if (a.userId < b.userId) {
              return -1 // TODO: Do an implementation that considers time of guess
            }
            return 1
          })
          .map((player, index) => {
            let guessesOfPlayer = guesses.find(guess => guess.player === player.userId)
            if (guessesOfPlayer == null || guessesOfPlayer.guessedCountries == null || guessesOfPlayer.length < roundNumber) {
              return <div/>;
            }
            console.log(guessesOfPlayer)
            return <RoundScoreboardEntryCountry
              position={index + 1}
              player={player}
              guessedCountry={getCountryInfo(guessesOfPlayer.guessedCountries[roundNumber - 1])}
              score={playersToScores[player.userId][roundNumber - 1]}
              isReady={playersToReadyRound[player.userId] > roundNumber}
            />
          })
      }
    </>
  )
}

export default RoundScoreboardCountry