import React from 'react'
import {Paper} from '@material-ui/core'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import UserService from '../../service/UserService'

import './Stats.scss'
import UserContext from "../../context/UserContext";


const BACKGROUND_COLOR_OPACITY = 0.2

const getOptions = eloHistory => ({
    chart: {
        zoomType: 'x'
    },
    title: {
        text: 'Your Elo Progression'
    },
    xAxis: {
        type: 'datetime'
    },
    yAxis: {
        title: {
            text: 'Elo points'
        },
        plotBands: [{
            from: 0,
            to: 900,
            color: `rgba(139, 69, 19, ${BACKGROUND_COLOR_OPACITY})`
        }, {
            from: 900,
            to: 1000,
            color: `rgba(255, 127, 80, ${BACKGROUND_COLOR_OPACITY})`
        }, {
            from: 1000,
            to: 1100,
            color: `rgba(192, 192, 192, ${BACKGROUND_COLOR_OPACITY})`
        }, {
            from: 1100,
            to: 1200,
            color: `rgba(255, 215, 0, ${BACKGROUND_COLOR_OPACITY})`
        }, {
            from: 1200,
            to: 1300,
            color: `rgba(119, 136, 153, ${BACKGROUND_COLOR_OPACITY})`
        }, {
            from: 1300,
            to: 1400,
            color: `rgba(0, 191, 255, ${BACKGROUND_COLOR_OPACITY})`
        }, {
            from: 1400,
            to: 1500,
            color: `rgba(0, 0, 255, ${BACKGROUND_COLOR_OPACITY})`
        }, {
            from: 1500,
            to: 1600,
            color: `rgba(199, 21, 133, ${BACKGROUND_COLOR_OPACITY})`
        }, {
            from: 1600,
            to: 1800,
            color: `rgba(0, 0, 0, ${BACKGROUND_COLOR_OPACITY})`
        }]
    },
    time: {
        useUTC: false
    },
    tooltip: {
        xDateFormat: '%d %m %y %H:%M'
    },
    series: [{
        type: 'line',
        name: 'Elo',
        showInLegend: false,
        data: eloHistory && eloHistory.map(point => [1000 * point.timestamp, point.elo])
    }]
})

class Stats extends React.Component {

    state = {
        options: getOptions()
    }

    componentDidMount() {
        console.log(this.props.user)
        if (this.props.user) {
            UserService.getEloHistory(this.props.user.userId, Intl.DateTimeFormat().resolvedOptions().timeZone)
              .then(eloHistory => this.setState({eloHistory}))
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(prevProps, this.props)
        if (this.state.options.series == null || this.state.options.series.length === 0 || this.state.options.series[0].data == null) {
            UserService.getEloHistory(this.props.user.userId, Intl.DateTimeFormat().resolvedOptions().timeZone)
              .then(eloHistory => this.setState({options: getOptions(eloHistory)}))
        }
    }

    render() {
        return <>
                <div className='stats'>
                    <Paper>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={this.state.options}
                        />
                    </Paper>
                </div>
        </>
    }
}

export default Stats