import appConf from '../conf/app-conf'

import axios from 'axios'

const logout = () => {
  axios
    .post(`${appConf.backendUrl}/logout`)
    .then(res => {})
}

const userInfo = () => new Promise((resolve, reject) => {
  fetch(`${appConf.backendUrl}/api/v1/user/info`)
    .then(res => res.json())
    .then(resolve)
    .catch(reject)
})

const changeUsername = username =>
  axios.get(`${appConf.backendUrl}/api/v1/user/changeusername`, {
    params: {
      username
    }
  })
    .then(res => res.data)

const getRanking = () =>
  axios.get(`${appConf.backendUrl}/api/v1/user/ranking`)
    .then(res => res.data)


const getEloHistory = (userId, tzone) =>
    axios.get(`${appConf.backendUrl}/api/v1/user/elohistory/${userId}`, {
      params: {
        tzone
      }
    })
        .then(res => res.data)


export default {logout, userInfo, changeUsername, getRanking, getEloHistory}