import React from 'react'
import RoundScoreMapView from '../../round-score-map-view'
import {withRouter} from 'react-router'
import queryString from 'query-string'
import GameService from '../../service/GameService'
import Card from '@material-ui/core/Card/Card'
import Grid from '@material-ui/core/Grid/Grid'
import Scoreboard from './Scoreboard'
import './RoundScore.scss'
import Button from '@material-ui/core/Button/Button'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import RoundScoreboard from './RoundScoreboard'
import UserContext from '../../context/UserContext'
import CloseIcon from '@material-ui/icons/Close'
import RoundScoreboardCountry from './RoundScoreboardCountry'


let gameRefetch


const playerGuesses = (game, roundNumber) =>
  game.players.map(player => {
    const guesses = game.guesses.find(guess => guess.player === player.userId)
    return {
      player: player,
      location: guesses && guesses.guesses && guesses.guesses.length >= roundNumber && guesses.guesses[roundNumber - 1],
      game: {}
    }
  })

const allPlayersReadyForRound = (game, roundNumber) => {
  for (let userId of Object.keys(game.playersToReadyRound)) {
    if (game.playersToReadyRound[userId] < roundNumber) {
      return false
    }
  }
  return true
}

const isReady = (userId, game, roundNumber) => game.playersToReadyRound[userId] > roundNumber

class RoundScore extends React.Component {

  constructor(props) {
    super(props)
    const queryStringData = queryString.parse(window.location.search)
    this.state = {
      gameId: queryStringData.gameId,
      game: null
    }
  }

  componentDidMount() {
    this.retrieveGame()
    gameRefetch = setInterval(this.retrieveGame.bind(this), 1000)
  }

  componentWillUnmount() {
    clearInterval(gameRefetch)
  }

  retrieveGame() {
    if (this.state.gameId && this.props.user != null) {
      GameService.getGame(this.state.gameId)
        .then(game => {
          let roundNumber
          if (game.gameSettings.gameMode === 'LOCATION') {
            roundNumber = game.playersToDistances[this.props.user.userId].length
          } else if (game.gameSettings.gameMode === 'COUNTRY') {
            roundNumber = game.playersToCountryCorrect[this.props.user.userId].length
          }

          this.setState({
            game,
            roundNumber
          })
          if (allPlayersReadyForRound(game, roundNumber + 1)) {
            this.nextRound()
          }
        })
    }
  }

  nextRound() {
    clearInterval(gameRefetch)
    this.props.history.push(`/game?gameId=${this.state.game.gameId}`)
  }

  setReady() {
    GameService.setReadyForRound(this.state.gameId, this.state.roundNumber + 1)
      .then(res => {
      })
      .catch(err => {
      })
  }

  setUnready() {
    GameService.setReadyForRound(this.state.gameId, this.state.roundNumber)
      .then(res => {
      })
      .catch(err => {
      })
  }

  viewSummary() {
    this.props.history.push(`/gamesummary?gameId=${this.state.game.gameId}`)
  }

  render() {
    if (!this.state.game) {
      return null
    }

    return this.props.user && (
      <Grid
        container
        spacing={24}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={8}
          xl={8}
        >
          <Card>
            <RoundScoreMapView
              googleMaps={this.props.googleMaps}
              correctLocations={[this.state.game.locations[this.state.roundNumber - 1]]}
              playerGuesses={[playerGuesses(this.state.game, this.state.roundNumber)]}
              height={750}
            />
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={4}
          xl={4}
        >
          <div className="scoreboard">
            <div className="totalScoreboard">
              <Card>
                <Scoreboard
                  players={this.state.game.players}
                  roundsCompleted={this.state.game.guesses.map(guess => ({
                    player: guess.player,
                    guesses: this.state.game.gameSettings.gameMode === 'LOCATION'
                      ? guess.guesses.length
                      : guess.guessedCountries.length}))
                  }
                  playerScores={this.state.game.playerScores}
                  locations={this.state.game.locations}
                />
              </Card>
            </div>
            <div className="thisRoundTitle"><h2>This round</h2></div>
            <div className="roundScoreboard">
              <Card>
                {this.state.game.gameSettings.gameMode === 'LOCATION' && (
                  <RoundScoreboard
                    roundNumber={this.state.roundNumber}
                    players={this.state.game.players}
                    playersToDistances={this.state.game.playersToDistances}
                    playersToScores={this.state.game.playersToScores}
                    playersToReadyRound={this.state.game.playersToReadyRound}
                    extraPointsForCorrectCountry={this.state.game.gameSettings.extraPointsForCorrectCountry}
                    playersToCountryCorrect={this.state.game.playersToCountryCorrect}
                  />
                )}
                {this.state.game.gameSettings.gameMode === 'COUNTRY' && (
                  <RoundScoreboardCountry
                    roundNumber={this.state.roundNumber}
                    players={this.state.game.players}
                    guesses={this.state.game.guesses}
                    playersToReadyRound={this.state.game.playersToReadyRound}
                    playersToCountryCorrect={this.state.game.playersToCountryCorrect}
                    playersToScores={this.state.game.playersToScores}
                  />
                )}
              </Card>
            </div>
          </div>
          <div className="nextRoundButton">
            {
              this.state.roundNumber < this.state.game.gameSettings.numberOfRounds ?
                <Button
                  variant="contained"
                  color="primary"
                  onClick={isReady(this.props.user.userId, this.state.game, this.state.roundNumber) ? this.setUnready.bind(this) : this.setReady.bind(this)}>
                  {isReady(this.props.user.userId, this.state.game, this.state.roundNumber) ? <CloseIcon/> :
                    <PlayArrowIcon/>}
                  {isReady(this.props.user.userId, this.state.game, this.state.roundNumber) ? 'Unready' : 'Ready'}
                </Button> :
                this.state.game.finished ?
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.viewSummary.bind(this)}>
                    <PlayArrowIcon/>
                    View summary
                  </Button> :
                  'Waiting for all players to finish...'
            }
          </div>
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(RoundScore)