import React from 'react'
import MapCountrySelection from './MapCountrySelection'
import {Button, Card, CardContent, MenuItem, Select, TextField} from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import CountrySelectionService from '../../service/CountrySelectionService'
import './Maps.scss'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import MakeGuessMapView from '../../make-guess-map-view'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import Dialog from '@material-ui/core/Dialog/Dialog'
import {streetViewCountries} from '../../static/StreetViewCountry'
import {mapCountryCodesToNames, mapCountryNamesToCodes} from '../../helper/helperfunctions'



class Maps extends React.Component {

  state = {
    creatingNewMap: true,
    selectedMap: null,
    availableMaps: [],
    countries: new Set(),
    createNewMapDialogOpen: false
  }

  componentDidMount() {
    CountrySelectionService.myMaps()
      .then(maps => this.setState({
        availableMaps: maps,
        selectedMap: maps != null && maps.length > 0
          ? maps[0]
          : null,
        creatingNewMap: maps == null || maps.length === 0,
        countries: maps != null && maps.length > 0 ? new Set(mapCountryNamesToCodes(maps[0].countries)) : new Set()
      }))
  }

  startingToCreateNewMap() {
    this.setState({
      creatingNewMap: true,
      selectedMap: null,
      countries: new Set()
    })
  }

  openCreateMapDialog() {
    this.setState({
      createNewMapDialogOpen: true
    })
  }

  closeCreateMapDialog() {
    this.setState({
      createNewMapDialogOpen: false,
      newMapName: null
    })
  }

  createNewMap() {
    CountrySelectionService.createNewMap({
      name: this.state.newMapName,
      countries: mapCountryCodesToNames([...this.state.countries])
    })
      .then(map => {
        // TODO: show snackbar that map has been created

        CountrySelectionService.myMaps()
          .then(maps => {
            this.setState({
              availableMaps: maps,
              selectedMap: map,
              creatingNewMap: false
            })
          })
      })
    this.closeCreateMapDialog()
  }

  updateMap() {
    if (this.state.selectedMap && this.state.selectedMap.id != null) {
      CountrySelectionService.updateMap(this.state.selectedMap.id, {
        name: this.state.selectedMap.name,
        countries: mapCountryCodesToNames([...this.state.countries])
      })
        .then(map => {
          // TODO: show snackbar that map has been edited
        })
    }
  }

  render() {
    return (
      <>
        <Dialog
          open={this.state.createNewMapDialogOpen}
          onClose={this.closeCreateMapDialog.bind(this)}
          maxWidth="xl"
          className="createNewMapDialog"
        >
          <DialogTitle>Name Your Map</DialogTitle>
          <DialogContent>
            <TextField
              value={this.state.newMapName}
              onChange={event => {
                this.setState({newMapName: event.target.value})
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeCreateMapDialog.bind(this)} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => this.createNewMap()}
              disabled={this.state.newMapName == null || this.state.newMapName.length === 0}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <div>
          <div className="mapSelectionMenu">
            <Card>
              <CardContent>
                <div className="mapSelectionMenuContent">
                  <div className="mapSelectionMenuItem">
                    Edit Map:
                    <div className="mapSelectionMenuComponent">
                      <Select
                        value={this.state.selectedMap ? this.state.selectedMap.id : ''}
                        fullWidth
                        onChange={event => {
                          console.log(event.target)
                          let selectedMap = this.state.availableMaps.find(map => map.id === event.target.value)
                          console.log(selectedMap)
                          this.setState({
                            selectedMap: selectedMap,
                            countries: new Set(selectedMap != null ? mapCountryNamesToCodes(selectedMap.countries) : []),
                            creatingNewMap: false
                          })
                        }}
                      >
                        {this.state.availableMaps.map(map => (
                          <MenuItem value={map.id}>{map.name}</MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <Button color="primary" onClick={this.startingToCreateNewMap.bind(this)} disabled={this.state.creatingNewMap}>
                    Create new map
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
          <MapCountrySelection
            googleMaps={this.props.googleMaps}
            countriesGeoJson={this.props.countriesGeoJson}
            width={1200}
            height={window.innerHeight - 300}
            countries={this.state.countries}
            editable
            addCountry={country => {
              let countries = new Set([...this.state.countries])
              countries.add(country)
              this.setState({countries})
            }}
            removeCountry={country => {
              let countries = new Set([...this.state.countries])
              countries.delete(country)
              this.setState({countries})
            }}
          />
          <div className="mapSelectionButton">
            {this.state.creatingNewMap && (
              <Button
                color="primary"
                variant="contained"
                onClick={this.openCreateMapDialog.bind(this)}
                disabled={this.state.countries.size === 0}
              >
                <AddCircleOutlineIcon/>
                Create map
              </Button>
            )}
            {!this.state.creatingNewMap && (
              <Button onClick={() => this.updateMap()}>
                Edit map
              </Button>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default Maps