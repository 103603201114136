import React from 'react'
import {Button, Card, CardContent, InputLabel, MenuItem, Select} from '@material-ui/core'
import TextField from '@material-ui/core/TextField/TextField'
import Switch from '@material-ui/core/Switch/Switch'
import './GameSettingsGuest.scss'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import LobbyMapDialog from './LobbyMapDialog'


class GameSettingsGuest extends React.Component {

  state = {
    mapDialogOpen: false
  }

  openMapDialog = () => {
    this.setState({mapDialogOpen: true})
  }

  closeMapDialog = () => {
    this.setState({mapDialogOpen: false})
  }

  render() {
    return (
      <>
        <LobbyMapDialog
          open={this.state.mapDialogOpen}
          closeDialog={this.closeMapDialog}
          map={this.props.gameSettings.countrySelection}
          googleMaps={this.props.googleMaps}
          countriesGeoJson={this.props.countriesGeoJson}
        />
        <Card className="gameSettingsGuestCard shadowBox transparentBackground">
          <CardContent>
            <div className="gameSettingsGuest">
              <div className="gameSettingsGuestRow">
                <InputLabel>
                  Game mode
                </InputLabel>
                <div className="gameSettingsGuestValue">
                  {this.props.gameSettings.gameMode === 'LOCATION' && 'Location'}
                  {this.props.gameSettings.gameMode === 'COUNTRY' && 'Country only'}
                </div>
              </div>
              <div className="gameSettingsGuestRow">
                <InputLabel>
                  Rounds per game
                </InputLabel>
                <div className="gameSettingsGuestValue">
                  {this.props.gameSettings.numberOfRounds}
                </div>
              </div>
              <div className="gameSettingsGuestRow">
                <InputLabel>
                  Time per round (sec)
                </InputLabel>
                <div className="gameSettingsGuestValue">
                  {this.props.gameSettings.timePerRound}
                </div>
              </div>
              <div className="gameSettingsGuestRow">
                <div className="gameSettingsGuestSwitchRow">
                  <Switch
                    checked={this.props.gameSettings.movingAllowed}
                    color="primary"
                  />
                  <div>Moving allowed</div>
                </div>
              </div>
              <div className="gameSettingsGuestRow">
                <div className="gameSettingsGuestSwitchRow">
                  <Switch
                    checked={this.props.gameSettings.withCompass}
                    color="primary"
                  />
                  <div>Compass</div>
                </div>
              </div>
              <div className="gameSettingsGuestRow">
                <div className="gameSettingsGuestSwitchRow">
                  <Switch
                    checked={this.props.gameSettings.extraPointsForCorrectCountry}
                    disabled={this.props.gameSettings.gameMode === 'COUNTRY'}
                    color="primary"
                  />
                  <div>Extra points for correct country</div>
                </div>
              </div>
              <div className="gameSettingsGuestRow">
                <InputLabel>
                  Location generation
                </InputLabel>
                <div className="gameSettingsGuestValue">
                  {this.props.gameSettings.locationGenerationType === 'BY_COUNTRY_SELECTION' && 'By country selection'}
                  {this.props.gameSettings.locationGenerationType === 'BY_AREA' && 'By area'}
                  {this.props.gameSettings.locationGenerationType === 'US_BY_POPULATION' && 'US by population'}
                </div>
              </div>
              {
                this.props.gameSettings.locationGenerationType === 'BY_COUNTRY_SELECTION' && (
                  <div className="gameSettingsGuestMap">
                    <div
                      className={this.props.gameSettings.countrySelection == null ? 'gameSettingsGuestRow' : 'gameSettingsGuestRowMap'}>
                      <InputLabel>
                        Map
                      </InputLabel>
                      <div className="gameSettingsGuestValue">
                        {this.props.gameSettings.countrySelection == null && 'WORLD'}
                        {this.props.gameSettings.countrySelection != null && this.props.gameSettings.countrySelection.name}
                      </div>
                    </div>
                    <div className="gameSettingsGuestMapInfoButtonContainer">
                      <Button className="gameSettingsGuestMapInfoButton" onClick={this.openMapDialog}>
                        <InfoOutlinedIcon/>
                      </Button>
                    </div>
                  </div>
                )
              }
            </div>
          </CardContent>
        </Card>
      </>
    )
  }
}

export default GameSettingsGuest
