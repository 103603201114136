import React from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import {formatPoints} from '../../helper/helperfunctions'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import './RoundScoreboardEntry.scss'


const RoundScoreboardEntryCountry = ({player, position, guessedCountry, score, isReady}) => {
  return (
    <>
      <div className='player'>
        <span className='positionIndicator'>{position}.</span>
        <img src={`data:image/jpeg;base64,${player.picture}`} width={40} height={40} alt=''/>
        <Grid container spacing={4} className='playerGrid'>
          <Grid
            item
            xs={5}
            sm={5}
            md={5}
            lg={5}
            xl={5}
          >
            <span className='playerUsername'>{player.username}</span>
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
          >
            {isReady && <FontAwesomeIcon icon={faCheck} color='green'/>}
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            className='countryFlagContainer'
          >
              <img src={`https://flagsapi.com/${guessedCountry.countryCode2}/flat/32.png`} title={guessedCountry.displayName} />
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
          >
            <span>{formatPoints(score)}</span>
          </Grid>
        </Grid>
      </div>
      <div className='playerSeparator'/>
    </>
  )
}

export default RoundScoreboardEntryCountry