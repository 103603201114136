import {randomFloat} from './helperfunctions'

const getRandomPointInConvexHull = coordinates => {
  let weightSum = 0
  let point = [0, 0]

  for (let i = 0; i < coordinates.length; i++) {
    let weight = Math.random()
    weightSum += weight
    for (let j = 0; j < 2; j++) {
      point[j] += weight * coordinates[i][j]
    }
  }

  if (weightSum === 0) {
    return getRandomPointInConvexHull(coordinates)
  }

  return [point[0] / weightSum, point[1] / weightSum]
}

const getRandomPointInOuterRectangle = coordinates => {
  let minX = getMinX(coordinates)
  let maxX = getMaxX(coordinates)
  let minY = getMinY(coordinates)
  let maxY = getMaxY(coordinates)

  return [randomFloat(minX, maxX), randomFloat(minY, maxY)]
}

const getMaxX = coordinates => {
  let maxX = Number.NEGATIVE_INFINITY
  for (let i = 0; i < coordinates.length; i++) {
    maxX = Math.max(maxX, coordinates[i][0])
  }
  return maxX
}

const getMaxY = coordinates => {
  let maxY = Number.NEGATIVE_INFINITY
  for (let i = 0; i < coordinates.length; i++) {
    maxY = Math.max(maxY, coordinates[i][1])
  }
  return maxY
}

const getMinX = coordinates => {
  let minX = Number.POSITIVE_INFINITY
  for (let i = 0; i < coordinates.length; i++) {
    minX = Math.min(minX, coordinates[i][0])
  }
  return minX
}

const getMinY = coordinates => {
  let minY = Number.POSITIVE_INFINITY
  for (let i = 0; i < coordinates.length; i++) {
    minY = Math.min(minY, coordinates[i][1])
  }
  return minY
}

const pointIsInsidePolygon = (point, polygonCoordinates) => {
  let numberOfIntersectionLeft = 0

  for (let i = 0; i < polygonCoordinates.length; i++) {
    if ((polygonCoordinates[i][1] >= point[1] && polygonCoordinates[(i+1) % polygonCoordinates.length][1] <= point[1]
      || polygonCoordinates[i][1] <= point[1] && polygonCoordinates[(i+1) % polygonCoordinates.length][1] >= point[1])
      && (point[1] - polygonCoordinates[i][1]) / (polygonCoordinates[(i+1) % polygonCoordinates.length][1] - polygonCoordinates[i][1])
        * (polygonCoordinates[(i+1) % polygonCoordinates.length][0] - polygonCoordinates[i][0]) < point[0] - polygonCoordinates[i][0]) {
      numberOfIntersectionLeft++
    }
  }

  return numberOfIntersectionLeft % 2 === 1
}


const getRandomPointInsidePolygon = coordinates => {
  let point

  do {
    point = getRandomPointInOuterRectangle(coordinates)
  } while(!pointIsInsidePolygon(point, coordinates))

  return point
}

export default {
  getRandomPointInsidePolygon
}