import React from 'react'
import Card from '@material-ui/core/Card/Card'
import RoundScoreMapView from '../../round-score-map-view'
import queryString from 'query-string'
import GameService from '../../service/GameService'
import GameSummaryTable from './GameSummaryTable'
import './GameSummaryTable.scss'
import Button from '@material-ui/core/Button/Button'
import {withRouter} from 'react-router'
import HomeIcon from '@material-ui/icons/Home'
import './GameSummary.scss'


const mapPlayerGuesses = (playerGuesses, numberOfRounds, players) => {
  const mappedPlayerGuesses = []
  for (let i = 0; i < numberOfRounds; i++) {
    mappedPlayerGuesses.push(playerGuesses.map(guessesPerPlayer => ({
      player: players.find(player => guessesPerPlayer.player === player.userId),
      location: guessesPerPlayer.guesses[i]
    })))
  }
  return mappedPlayerGuesses
}

class GameSummary extends React.Component {

  constructor(props) {
    super(props)
    const queryStringData = queryString.parse(window.location.search)
    this.state = {
      gameId: queryStringData.gameId,
      game: {}
    }
  }

  componentDidMount() {
    if (this.state.gameId) {
      GameService
        .getGame(this.state.gameId)
        .then(game => this.setState({game}))
    }
  }

  render() {
    if (this.state.game.gameId) {
      return (
        <>
          <div className="gameSummaryMap">
            <Card>
              <RoundScoreMapView
                googleMaps={this.props.googleMaps}
                correctLocations={this.state.game.locations}
                playerGuesses={this.state.game.gameSettings.gameMode === 'LOCATION' ? (
                  mapPlayerGuesses(
                    this.state.game.guesses,
                    this.state.game.gameSettings.numberOfRounds,
                    this.state.game.players
                  )
                ) : null}
                height={500}
              />
            </Card>
          </div>
          <div className="gameSummaryTable">
            <Card>
              <div className="gameSummaryTableContent">
                <GameSummaryTable
                  game={this.state.game}
                />
              </div>
            </Card>
          </div>
          <div className="homeButton">
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.props.history.push('/')}
            >
              <HomeIcon/>
              Home
            </Button>
          </div>
        </>
      )
    }
    return null
  }
}

export default withRouter(GameSummary)