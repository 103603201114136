import React from 'react'
import RoundScoreboardEntry from './RoundScoreboardEntry'


const RoundScoreboard = ({
                           roundNumber, players, playersToDistances, playersToScores, playersToReadyRound,
                           extraPointsForCorrectCountry, playersToCountryCorrect
}) => {
  return (
    <>
      {
        [...players]
          .filter(player => playersToDistances[player.userId] && playersToScores[player.userId]
            && playersToDistances[player.userId][roundNumber - 1] != null
            && playersToScores[player.userId][roundNumber - 1] != null)
          .sort((a, b) => playersToDistances[a.userId][roundNumber - 1] - playersToDistances[b.userId][roundNumber - 1])
          .map((player, index) =>
            <RoundScoreboardEntry
              position={index + 1}
              player={player}
              distance={playersToDistances[player.userId][roundNumber - 1]}
              score={playersToScores[player.userId][roundNumber - 1]}
              isReady={playersToReadyRound[player.userId] > roundNumber}
              extraPoints={extraPointsForCorrectCountry && playersToCountryCorrect[player.userId][roundNumber - 1] ? 2000 : undefined}
            />
        )
      }
    </>
  )
}

export default RoundScoreboard