const streetViewCountries = {
  ALB: {
    name: 'ALBANIA',
    displayName: 'Albania',
    countryCode2: 'AL',
    countryCode3: 'ALB',
    microState: false,
    active: true
  },
  ASM: {
    name: 'AMERICAN_SAMOA',
    displayName: 'American Samoa',
    countryCode2: 'AS',
    countryCode3: 'ASM',
    microState: true,
    active: true
  },
  AND: {
    name: 'ANDORRA',
    displayName: 'Andorra',
    countryCode2: 'AD',
    countryCode3: 'AND',
    microState: true,
    active: true
  },
  ARG: {
    name: 'ARGENTINA',
    displayName: 'Argentina',
    countryCode2: 'AR',
    countryCode3: 'ARG',
    microState: false,
    active: true
  },
  AUS: {
    name: 'AUSTRALIA',
    displayName: 'Australia',
    countryCode2: 'AU',
    countryCode3: 'AUS',
    microState: false,
    active: true
  },
  AUT: {
    name: 'AUSTRIA',
    displayName: 'Austria',
    countryCode2: 'AT',
    countryCode3: 'AUT',
    microState: false,
    active: true
  },
  BGD: {
    name: 'BANGLADESH',
    displayName: 'Bangladesh',
    countryCode2: 'BD',
    countryCode3: 'BGD',
    microState: false,
    active: true
  },
  BEL: {
    name: 'BELGIUM',
    displayName: 'Beglium',
    countryCode2: 'BE',
    countryCode3: 'BEL',
    microState: false,
    active: true
  },
  BMU: {
    name: 'BERMUDA',
    displayName: 'Bermuda',
    countryCode2: 'BM',
    countryCode3: 'BMU',
    microState: true,
    active: true
  },
  BTN: {
    name: 'BHUTAN',
    displayName: 'Bhutan',
    countryCode2: 'BT',
    countryCode3: 'BTN',
    microState: false,
    active: true
  },
  BOL: {
    name: 'BOLIVIA',
    displayName: 'Bolivia',
    countryCode2: 'BO',
    countryCode3: 'BOL',
    microState: false,
    active: true
  },
  BWA: {
    name: 'BOTSWANA',
    displayName: 'Botswana',
    countryCode2: 'BW',
    countryCode3: 'BWA',
    microState: false,
    active: true
  },
  BRA: {
    name: 'BRAZIL',
    displayName: 'Brazil',
    countryCode2: 'BR',
    countryCode3: 'BRA',
    microState: false,
    active: true
  },
  IOT: {
    name: 'BRITISH_INDIAN_OCEAN_TERRITORY',
    displayName: 'British Indian Ocean Territory',
    countryCode2: 'IO',
    countryCode3: 'IOT',
    microState: true,
    active: true
  },
  BGR: {
    name: 'BULGARIA',
    displayName: 'Bulgaria',
    countryCode2: 'BG',
    countryCode3: 'BGR',
    microState: false,
    active: true
  },
  KHM: {
    name: 'CAMBODIA',
    displayName: 'Cambodia',
    countryCode2: 'KH',
    countryCode3: 'KHM',
    microState: false,
    active: true
  },
  CAN: {
    name: 'CANADA',
    displayName: 'Canada',
    countryCode2: 'CA',
    countryCode3: 'CAN',
    microState: false,
    active: true
  },
  CHL: {
    name: 'CHILE',
    displayName: 'Chile',
    countryCode2: 'CL',
    countryCode3: 'CHL',
    microState: false,
    active: true
  },
  COL: {
    name: 'COLOMBIA',
    displayName: 'Colombia',
    countryCode2: 'CO',
    countryCode3: 'COL',
    microState: false,
    active: true
  },
  HRV: {
    name: 'CROATIA',
    displayName: 'Croatia',
    countryCode2: 'HR',
    countryCode3: 'HRV',
    microState: false,
    active: true
  },
  CUW: {
    name: 'CURACAO',
    displayName: 'Curaçao',
    countryCode2: 'CW',
    countryCode3: 'CUW',
    microState: true,
    active: true
  },
  CZE: {
    name: 'CZECH_REPUBLIC',
    displayName: 'Czech Republic',
    countryCode2: 'CZ',
    countryCode3: 'CZE',
    microState: false,
    active: true
  },
  DNK: {
    name: 'DENMARK',
    displayName: 'Denmark',
    countryCode2: 'DK',
    countryCode3: 'DNK',
    microState: false,
    active: true
  },
  DOM: {
    name: 'DOMINICAN_REPUBLIC',
    displayName: 'Dominican Republic',
    countryCode2: 'DO',
    countryCode3: 'DOM',
    microState: false,
    active: true
  },
  ECU: {
    name: 'ECUADOR',
    displayName: 'Ecuador',
    countryCode2: 'EC',
    countryCode3: 'ECU',
    microState: false,
    active: true
  },
  EST: {
    name: 'ESTONIA',
    displayName: 'Estonia',
    countryCode2: 'EE',
    countryCode3: 'EST',
    microState: false,
    active: true
  },
  SWZ: {
    name: 'ESWATINI',
    displayName: 'Eswatini',
    countryCode2: 'SZ',
    countryCode3: 'SWZ',
    microState: false,
    active: true
  },
  FRO: {
    name: 'FAROE_ISLANDS',
    displayName: 'Faroe Islands',
    countryCode2: 'FO',
    countryCode3: 'FRO',
    microState: false,
    active: true
  },
  FIN: {
    name: 'FINLAND',
    displayName: 'Finland',
    countryCode2: 'FI',
    countryCode3: 'FIN',
    microState: false,
    active: true
  },
  FRA: {
    name: 'FRANCE',
    displayName: 'France',
    countryCode2: 'FR',
    countryCode3: 'FRA',
    microState: false,
    active: true
  },
  DEU: {
    name: 'GERMANY',
    displayName: 'Germany',
    countryCode2: 'DE',
    countryCode3: 'DEU',
    microState: false,
    active: true
  },
  GHA: {
    name: 'GHANA',
    displayName: 'Ghana',
    countryCode2: 'GH',
    countryCode3: 'GHA',
    microState: false,
    active: true
  },
  GIB: {
    name: 'GIBRALTAR',
    displayName: 'Gibraltar',
    countryCode2: 'GI',
    countryCode3: 'GIB',
    microState: true,
    active: true
  },
  GRC: {
    name: 'GREECE',
    displayName: 'Greece',
    countryCode2: 'GR',
    countryCode3: 'GRC',
    microState: false,
    active: true
  },
  GRL: {
    name: 'GREENLAND',
    displayName: 'Greenland',
    countryCode2: 'GL',
    countryCode3: 'GRL',
    microState: false,
    active: false
  },
  GTM: {
    name: 'GUATEMALA',
    displayName: 'Guatemala',
    countryCode2: 'GT',
    countryCode3: 'GTM',
    microState: false,
    active: true
  },
  HKG: {
    name: 'HONG_KONG',
    displayName: 'Honk Kong',
    countryCode2: 'HK',
    countryCode3: 'HKG',
    microState: true,
    active: true
  },
  HUN: {
    name: 'HUNGARY',
    displayName: 'Hungary',
    countryCode2: 'HU',
    countryCode3: 'HUN',
    microState: false,
    active: true
  },
  ISL: {
    name: 'ICELAND',
    displayName: 'Iceland',
    countryCode2: 'IS',
    countryCode3: 'ISL',
    microState: false,
    active: true
  },
  IND: {
    name: 'INDIA',
    displayName: 'India',
    countryCode2: 'IN',
    countryCode3: 'IND',
    microState: false,
    active: true
  },
  IDN: {
    name: 'INDONESIA',
    displayName: 'Indonesia',
    countryCode2: 'ID',
    countryCode3: 'IDN',
    microState: false,
    active: true
  },
  IRL: {
    name: 'IRELAND',
    displayName: 'Ireland',
    countryCode2: 'IE',
    countryCode3: 'IRL',
    microState: false,
    active: true
  },
  IMN: {
    name: 'ISLE_OF_MAN',
    displayName: 'Isle of Man',
    countryCode2: 'IM',
    countryCode3: 'IMN',
    microState: true,
    active: true
  },
  ISR: {
    name: 'ISRAEL',
    displayName: 'Israel',
    countryCode2: 'IL',
    countryCode3: 'ISR',
    microState: false,
    active: true
  },
  ITA: {
    name: 'ITALY',
    displayName: 'Italy',
    countryCode2: 'IT',
    countryCode3: 'ITA',
    microState: false,
    active: true
  },
  JPN: {
    name: 'JAPAN',
    displayName: 'Japan',
    countryCode2: 'JP',
    countryCode3: 'JPN',
    microState: false,
    active: true
  },
  JEY: {
    name: 'JERSEY',
    displayName: 'Jersey',
    countryCode2: 'JE',
    countryCode3: 'JEY',
    microState: true,
    active: true
  },
  JOR: {
    name: 'JORDAN',
    displayName: 'Jordan',
    countryCode2: 'JO',
    countryCode3: 'JOR',
    microState: false,
    active: true
  },
  KEN: {
    name: 'KENYA',
    displayName: 'Kenya',
    countryCode2: 'KE',
    countryCode3: 'KEN',
    microState: false,
    active: true
  },
  KOR: {
    name: 'SOUTH_KOREA',
    displayName: 'South Korea',
    countryCode2: 'KR',
    countryCode3: 'KOR',
    microState: false,
    active: true
  },
  KAZ: {
    name: 'KAZAKHSTAN',
    displayName: 'Kazakhstan',
    countryCode2: 'KZ',
    countryCode3: 'KAZ',
    microState: false,
    active: true
  },
  KGZ: {
    name: 'KYRGYZSTAN',
    displayName: 'Kyrgyzstan',
    countryCode2: 'KG',
    countryCode3: 'KGZ',
    microState: false,
    active: true
  },
  LVA: {
    name: 'LATVIA',
    displayName: 'Latvia',
    countryCode2: 'LV',
    countryCode3: 'LVA',
    microState: false,
    active: true
  },
  LSO: {
    name: 'LESOTHO',
    displayName: 'Lesotho',
    countryCode2: 'LS',
    countryCode3: 'LSO',
    microState: false,
    active: true
  },
  LTU: {
    name: 'LITHUANIA',
    displayName: 'Lithuania',
    countryCode2: 'LT',
    countryCode3: 'LTU',
    microState: false,
    active: true
  },
  LUX: {
    name: 'LUXEMBOURG',
    displayName: 'Luxembourg',
    countryCode2: 'LU',
    countryCode3: 'LUX',
    microState: true,
    active: true
  },
  MAC: {
    name: 'MACAO',
    displayName: 'Macao',
    countryCode2: 'MO',
    countryCode3: 'MAC',
    microState: true,
    active: true
  },
  MYS: {
    name: 'MALAYSIA',
    displayName: 'Malaysia',
    countryCode2: 'MY',
    countryCode3: 'MYS',
    microState: false,
    active: true
  },
  MLT: {
    name: 'MALTA',
    displayName: 'Malta',
    countryCode2: 'MT',
    countryCode3: 'MLT',
    microState: true,
    active: true
  },
  MEX: {
    name: 'MEXICO',
    displayName: 'Mexico',
    countryCode2: 'MX',
    countryCode3: 'MEX',
    microState: false,
    active: true
  },
  MCO: {
    name: 'MONACO',
    displayName: 'Monaco',
    countryCode2: 'MC',
    countryCode3: 'MCO',
    microState: true,
    active: true
  },
  MNG: {
    name: 'MONGOLIA',
    displayName: 'Mongolia',
    countryCode2: 'MN',
    countryCode3: 'MNG',
    microState: false,
    active: true
  },
  MNE: {
    name: 'MONTENEGRO',
    displayName: 'Montenegro',
    countryCode2: 'ME',
    countryCode3: 'MNE',
    microState: false,
    active: true
  },
  NLD: {
    name: 'NETHERLANDS',
    displayName: 'Netherlands',
    countryCode2: 'NL',
    countryCode3: 'NLD',
    microState: false,
    active: true
  },
  NZL: {
    name: 'NEW_ZEALAND',
    displayName: 'New Zealand',
    countryCode2: 'NZ',
    countryCode3: 'NZL',
    microState: false,
    active: true
  },
  NGA: {
    name: 'NIGERIA',
    displayName: 'Nigeria',
    countryCode2: 'NG',
    countryCode3: 'NGA',
    microState: false,
    active: true
  },
  MKD: {
    name: 'NORTH_MACEDONIA',
    displayName: 'North Macedonia',
    countryCode2: 'MK',
    countryCode3: 'MKD',
    microState: false,
    active: true
  },
  MNP: {
    name: 'NORTHERN_MARIANA_ISLANDS',
    displayName: 'Northern Mariana Islands',
    countryCode2: 'MP',
    countryCode3: 'MNP',
    microState: true,
    active: true
  },
  NOR: {
    name: 'NORWAY',
    displayName: 'Norway',
    countryCode2: 'NO',
    countryCode3: 'NOR',
    microState: false,
    active: true
  },
  PSE: {
    name: 'PALESTINE',
    displayName: 'Palestine',
    countryCode2: 'PS',
    countryCode3: 'PSE',
    microState: false,
    active: true
  },
  PER: {
    name: 'PERU',
    displayName: 'Peru',
    countryCode2: 'PE',
    countryCode3: 'PER',
    microState: false,
    active: true
  },
  PHL: {
    name: 'PHILIPPINES',
    displayName: 'Philippines',
    countryCode2: 'PH',
    countryCode3: 'PHL',
    microState: false,
    active: true
  },
  PCN: {
    name: 'PITCAIRN_ISLANDS',
    displayName: 'Pitcairn Islands',
    countryCode2: 'PN',
    countryCode3: 'PCN',
    microState: true,
    active: true
  },
  POL: {
    name: 'POLAND',
    displayName: 'Poland',
    countryCode2: 'PL',
    countryCode3: 'POL',
    microState: false,
    active: true
  },
  PRT: {
    name: 'PORTUGAL',
    displayName: 'Portugal',
    countryCode2: 'PT',
    countryCode3: 'PRT',
    microState: false,
    active: true
  },
  PRI: {
    name: 'PUERTO_RICO',
    displayName: 'Puerto Rico',
    countryCode2: 'PR',
    countryCode3: 'PRI',
    microState: false,
    active: true
  },
  QAT: {
    name: 'QATAR',
    displayName: 'Qatar',
    countryCode2: 'QA',
    countryCode3: 'QAT',
    microState: false,
    active: true
  },
  ROU: {
    name: 'ROMANIA',
    displayName: 'Romania',
    countryCode2: 'RO',
    countryCode3: 'ROU',
    microState: false,
    active: true
  },
  RUS: {
    name: 'RUSSIA',
    displayName: 'Russia',
    countryCode2: 'RU',
    countryCode3: 'RUS',
    microState: false,
    active: true
  },
  SPM: {
    name: 'SAINT_PIERRE_AND_MIQUELON',
    displayName: 'Saint Pierre and Miquelon',
    countryCode2: 'PM',
    countryCode3: 'SPM',
    microState: true,
    active: true
  },
  SMR: {
    name: 'SAN_MARINO',
    displayName: 'San Marino',
    countryCode2: 'SM',
    countryCode3: 'SMR',
    microState: true,
    active: true
  },
  SEN: {
    name: 'SENEGAL',
    displayName: 'Senegal',
    countryCode2: 'SN',
    countryCode3: 'SEN',
    microState: false,
    active: true
  },
  SRB: {
    name: 'SERBIA',
    displayName: 'Serbia',
    countryCode2: 'RS',
    countryCode3: 'SRB',
    microState: false,
    active: true
  },
  SGP: {
    name: 'SINGAPORE',
    displayName: 'Singapore',
    countryCode2: 'SG',
    countryCode3: 'SGP',
    microState: true,
    active: true
  },
  SVK: {
    name: 'SLOVAKIA',
    displayName: 'Slovakia',
    countryCode2: 'SK',
    countryCode3: 'SVK',
    microState: false,
    active: true
  },
  SVN: {
    name: 'SLOVENIA',
    displayName: 'Slovenia',
    countryCode2: 'SI',
    countryCode3: 'SVN',
    microState: false,
    active: true
  },
  ZAF: {
    name: 'SOUTH_AFRICA',
    displayName: 'South Africa',
    countryCode2: 'ZA',
    countryCode3: 'ZAF',
    microState: false,
    active: true
  },
  SGS: {
    name: 'SOUTH_GEORGIA_AND_SOUTH_SANDWICH_ISLANDS',
    displayName: 'South Georgia and South Sandwich Islands',
    countryCode2: 'GS',
    countryCode3: 'SGS',
    microState: true,
    active: true
  },
  ESP: {
    name: 'SPAIN',
    displayName: 'Spain',
    countryCode2: 'ES',
    countryCode3: 'ESP',
    microState: false,
    active: true
  },
  LKA: {
    name: 'SRI_LANKA',
    displayName: 'Sri Lanka',
    countryCode2: 'LK',
    countryCode3: 'LKA',
    microState: false,
    active: true
  },
  SWE: {
    name: 'SWEDEN',
    displayName: 'Sweden',
    countryCode2: 'SE',
    countryCode3: 'SWE',
    microState: false,
    active: true
  },
  CHE: {
    name: 'SWITZERLAND',
    displayName: 'Switzerland',
    countryCode2: 'CH',
    countryCode3: 'CHE',
    microState: false,
    active: true
  },
  TWN: {
    name: 'TAIWAN',
    displayName: 'Taiwan',
    countryCode2: 'TW',
    countryCode3: 'TWN',
    microState: false,
    active: true
  },
  THA: {
    name: 'THAILAND',
    displayName: 'Thailand',
    countryCode2: 'TH',
    countryCode3: 'THA',
    microState: false,
    active: true
  },
  TUN: {
    name: 'TUNISIA',
    displayName: 'Tunisia',
    countryCode2: 'TN',
    countryCode3: 'TUN',
    microState: false,
    active: true
  },
  TUR: {
    name: 'TURKEY',
    displayName: 'Türkiye',
    countryCode2: 'TR',
    countryCode3: 'TUR',
    microState: false,
    active: true
  },
  UGA: {
    name: 'UGANDA',
    displayName: 'Uganda',
    countryCode2: 'UG',
    countryCode3: 'UGA',
    microState: false,
    active: true
  },
  UKR: {
    name: 'UKRAINE',
    displayName: 'Ukraine',
    countryCode2: 'UA',
    countryCode3: 'UKR',
    microState: false,
    active: true
  },
  ARE: {
    name: 'UNITED_ARAB_EMIRATES',
    displayName: 'United Arab Emirates',
    countryCode2: 'AE',
    countryCode3: 'ARE',
    microState: false,
    active: true
  },
  GBR: {
    name: 'UNITED_KINGDOM',
    displayName: 'United Kingdom',
    countryCode2: 'GB',
    countryCode3: 'GBR',
    microState: false,
    active: true
  },
  USA: {
    name: 'UNITED_STATES',
    displayName: 'United States',
    countryCode2: 'US',
    countryCode3: 'USA',
    microState: false,
    active: true
  },
  VIR: {
    name: 'UNITED_STATES_VIRGIN_ISLANDS',
    displayName: 'United States Virgin Islands',
    countryCode2: 'VI',
    countryCode3: 'VIR',
    microState: true,
    active: true
  },
  URY: {
    name: 'URUGUAY',
    displayName: 'Uruguay',
    countryCode2: 'UY',
    countryCode3: 'URY',
    microState: false,
    active: true
  }
}


export {streetViewCountries}