import React from 'react'
import GameService from '../../service/GameService'
import UserContext from '../../context/UserContext'
import PastGame from './PastGame'
import Grid from '@material-ui/core/Grid'
import './PastGamesOverview.scss'
import moment from 'moment'
import Pagination from 'material-ui-flat-pagination'


const PAGE_LIMIT = 24

class PastGamesOverview extends React.Component {

  state = {
    offset: 0,
    total: 0
  }

  componentDidMount() {
    this.loadGames()
  }

  loadGames() {
    GameService.getGamesByFinished(true, this.state.offset, PAGE_LIMIT)
      .then(({games, total}) => this.setState({games, total}))
  }

  changePage = (e, offset) => this.setState({offset}, this.loadGames.bind(this))

  render() {
    if (!this.state.games) {
      return null
    }
    return <div className="pastGamesOverview">
      <h2 className="homeTitle shadowText">Past Games</h2>
      <UserContext.Consumer>
        {user => <>
          <Grid
            container
            alignItems="flex-start"
            spacing={8}
          >
            {this.state.games && this.state.games.length > 0 ? [...this.state.games]
                .sort((a, b) => moment(b.started).unix() - moment(a.started).unix())
                .map(game => <Grid
                  item
                  xs={6}
                  sm={4}
                  md={4}
                  lg={3}
                  xl={3}
                >
                  <div className="pastGameDiv">
                    <PastGame game={game} user={user}/>
                  </div>
                </Grid>) :
              <div className="noGamesDiv">
                You haven't played any games yet.
              </div>
            }
          </Grid>
          <div className="pastGamesPagination">
            <Pagination
              limit={PAGE_LIMIT}
              offset={this.state.offset}
              total={this.state.total}
              onClick={this.changePage.bind(this)}
            />
          </div>
        </>
        }
      </UserContext.Consumer>
    </div>
  }
}

export default PastGamesOverview