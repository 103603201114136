import React from 'react'
import TextField from '@material-ui/core/TextField'
import UserService from '../../service/UserService'
import isEqual from 'lodash.isequal'
import {Button, Card, Grid} from '@material-ui/core'
import './Settings.scss'
import {withRouter} from 'react-router'


class Settings extends React.Component {

  state = {
    user: null
  }

  componentDidMount() {
    this.setState({user: this.props.user})
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.user, this.props.user)) {
      this.setState({user: this.props.user})
    }
  }

  changeUsername = event => {
    const username = event.target.value
    this.setState({
      user: {
        ...this.state.user,
        username
      }
    })
  }

  applyChange = () => {
    UserService.changeUsername(this.state.user.username).then(user => {
      window.location.reload()
    })
  }

  changeButtonAvailable = () => {
    if (isEqual(this.state.user, this.props.user)) {
      return false
    }
    if (this.state.user.username.length < 3) {
      return false
    }
    return true
  }

  render() {
    if (!this.state.user) return null
    return (
      <Grid container>
        <Grid
          item
          xs={0}
          sm={0}
          md={1}
          lg={2}
          xl={3}
        >
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={8}
          xl={6}
        >
          <Card>
            <div className='settingsContent'>
              <img src={`data:image/jpeg;base64,${this.state.user.picture}`} width={128} height={128} alt=''/>
              <div className='settingsUsername'>
                <TextField
                  id='username'
                  label='Username'
                  type='text'
                  value={this.state.user.username}
                  onChange={this.changeUsername}
                />
              </div>
            </div>
          </Card>
          <div className='applyButtonCard'>
            <Card>
              <div className='applyButton'>
                <Button
                  color='primary'
                  disabled={!this.changeButtonAvailable()}
                  onClick={this.applyChange}
                >
                  Apply changes
                </Button>
              </div>
            </Card>
          </div>
        </Grid>
        <Grid
          item
          xs={0}
          sm={0}
          md={1}
          lg={2}
          xl={3}
        >
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(Settings)