import React from 'react'
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core'
import numeral from 'numeral'
import UserService from '../../service/UserService'
import {displayRankIcon} from '../../helper/helperfunctions'

import './Ranking.scss'


class Ranking extends React.Component {

  state = {
    rankings: []
  }

  componentDidMount() {
    UserService.getRanking()
      .then(rankings => this.setState({rankings}))
  }

  render() {
    return <div className='ranking'>
      <Paper className='tablePaper'>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className='positionCell'/>
              <TableCell>User</TableCell>
              <TableCell align='right'>Rating</TableCell>
              <TableCell className='rankIconCell'/>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.rankings && this.state.rankings.map((userRanking, index) => (
                <TableRow key={userRanking.userId}>
                  <TableCell>{numeral(index + 1).format('0, 0')}.</TableCell>
                  <TableCell>
                    <div className='userEntry'>
                      <img src={`data:image/jpeg;base64,${userRanking.picture}`} width={32} height={32} alt=''/>
                      <span className='playerUsername'>{userRanking.username}</span>
                    </div>
                  </TableCell>
                  <TableCell align='right'>{numeral(userRanking.elo).format('0,0')}</TableCell>
                  <TableCell>{displayRankIcon(userRanking.rankDefinition)}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </Paper>
    </div>
  }
}

export default Ranking