import React from 'react'
import Card from '@material-ui/core/Card'
import {CardContent} from '@material-ui/core'
import moment from 'moment'
import {faCompass, faRunning} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {withRouter} from 'react-router'
import Button from '@material-ui/core/Button'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import './OngoingGame.scss'
import GamePlayersPreview from './GamePlayersPreview'


const OngoingGame = ({history, game, user}) => {
  if (!game || !user) {
    return null
  }
  const continueGame = () => history.push(`/game?gameId=${game.gameId}`)
  const playerGuesses = game.guesses.find(guess => guess.player === user.userId)

  return (
    <Card className="shadowBox transparentBackground">
      <CardContent>
        <div className="ongoingGame">
          {moment(game.started).fromNow()}<br/>
          Round {playerGuesses ? playerGuesses.guesses.length : 0}/{game.locations.length}<br/>
          <div className="ongoingGameRow">
            <FontAwesomeIcon icon={faRunning} color={game.gameSettings.movingAllowed ? 'green' : 'red'}/>
            <span className="nextToIconLabel">{game.gameSettings.movingAllowed ? 'Moving' : 'No moving'}</span>
          </div>
          <div className="ongoingGameRow">
            <FontAwesomeIcon icon={faCompass} color={game.gameSettings.withCompass ? 'green' : 'red'}/>
            <span className="nextToIconLabel">{game.gameSettings.withCompass ? 'Compass' : 'No compass'}</span>
          </div>
          <div className="ongoingGamePlayersPreview">
            <GamePlayersPreview game={game} user={user}/>
          </div>
          <div className="ongoingGameSpace"/>
          <Button color="primary" onClick={continueGame}>
            <PlayArrowIcon/>
            Continue game
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}

export default withRouter(OngoingGame)