import React from 'react'
import ReactDOM from 'react-dom'
import {streetViewCountries} from '../../static/StreetViewCountry'
import _ from 'lodash'


const getPolygonOptions = (paths, countryAvailable, countrySelected) => ({
  paths: paths,
  strokeColor: "#444444",
  strokeOpacity: 0.8,
  strokeWeight: countryAvailable ? 1 : 0.5,
  fillColor: countrySelected ? "#00FF00" : "#444444",
  fillOpacity: countryAvailable ? (countrySelected ? 0.5 : 0.25) : 0
})

class MapCountrySelection extends React.Component {

  state = {
    countryToPolygons: {}
  }

  initialize(canvas) {
    if (!this.state.map) {
      let mapTypeId = 'noborders'

      let map = new this.props.googleMaps.Map(
        canvas, {
          center: {lat: 0, lng: 0},
          zoom: 1,
          clickableIcons: false,
          streetViewControl: false,
          mapTypeControl: false,
          mapTypeControlOptions: {
            mapTypeIds: ['roadmap']
          },
          mapTypeId
        }
      )

      let featureOptions = [
        {
          featureType: "administrative.country",
          elementType: "geometry.fill",
          stylers: [
            { visibility: "off" }
          ]
        },
        {
          featureType: "administrative.country",
          elementType: "geometry.stroke",
          stylers: [
            { visibility: "off" }
          ]
        }
      ];

      let customMapType = new this.props.googleMaps.StyledMapType(featureOptions, {})
      map.mapTypes.set(mapTypeId, customMapType)

      this.setState({
          map: map
        },
        this.drawAllCountryPolygons
      )
    }
  }

  drawAllCountryPolygons() {
    let countryToPolygons = {}
    if (this.props.countriesGeoJson != null) {
      let geoJsonFeatures = this.props.countriesGeoJson.features
      geoJsonFeatures.sort((a, b) => {
        let countryCodeA = a.properties.ISO_A3
        let countryCodeB = b.properties.ISO_A3
        if (countryCodeA === countryCodeB) {
          return 0
        }
        if (countryCodeA < countryCodeB) {
          return 1
        }
        return -1
      })
      for (const feature of geoJsonFeatures) {
        let countryCode = feature.properties.ISO_A3
        for (let polygon of feature.geometry.coordinates) {
          if (feature.geometry.type === 'MultiPolygon') {
            polygon = polygon[0]
          }
          let paths = polygon.map(([lng, lat]) => ({lng, lat}))
          let countryIsAvailableAsStreetViewCountry = streetViewCountries[countryCode] != null && streetViewCountries[countryCode].active

          let polygonObject = new this.props.googleMaps.Polygon(
            getPolygonOptions(
              paths,
              countryIsAvailableAsStreetViewCountry,
              this.props.countries.has(countryCode)
            )
          )

          polygonObject.setMap(this.state.map)

          if (this.props.editable && countryIsAvailableAsStreetViewCountry) {
            this.props.googleMaps.event.addListener(polygonObject, 'click', event => {
              if (this.props.countries.has(countryCode)) {
                this.props.removeCountry(countryCode)
                for (const polygonObjectOfCountry of this.state.countryToPolygons[countryCode]) {
                  polygonObjectOfCountry.setOptions(getPolygonOptions(polygonObjectOfCountry.getPaths(), true, false))
                }
              } else {
                this.props.addCountry(countryCode)
                for (const polygonObjectOfCountry of this.state.countryToPolygons[countryCode]) {
                  polygonObjectOfCountry.setOptions(getPolygonOptions(polygonObjectOfCountry.getPaths(), true, true))
                }
              }
            })
          }

          if (countryToPolygons[countryCode] == null) {
            countryToPolygons[countryCode] = []
          }
          countryToPolygons[countryCode].push(polygonObject)
        }
      }
    }

    this.setState({countryToPolygons})

  }

  componentDidMount() {
    this.initialize(ReactDOM.findDOMNode(this))
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps, this.props) || !_.isEqual(prevState, this.state)) {
      let countryToPolygonEntries = Object.entries(this.state.countryToPolygons)
      countryToPolygonEntries.sort(([countryA, polygonA], [countryB, polygonB]) => {
        if (countryA === countryB) {
          return 0
        }
        if (countryA < countryB) {
          return 1
        }
        return -1
      })
      for (const [country, polygonObjects] of countryToPolygonEntries) {
        if (streetViewCountries[country] != null && streetViewCountries[country].active) {
          for (const polygonObject of polygonObjects) {
            polygonObject.setOptions(getPolygonOptions(polygonObject.getPaths(), true, this.props.countries.has(country)))
          }
        }
      }
    }
  }

  render() {
    return <div
      style={{
        height: `${this.props.height}px`,
        width: `${this.props.width}px`
      }}
    ></div>
  }
}

export default MapCountrySelection