import React from 'react'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import {
  displayRankIcon,
  formatDistance,
  formatElo,
  formatPoints, getCountryInfo,
  mapCountryNamesToCodes
} from '../../helper/helperfunctions'
import numeral from 'numeral'


const playersWithEloDifference = game => {
  const players = [...game.players]

  Object.entries(game.playersToEloDifference).forEach(([playersString, eloDifference]) => {
    const parsedPlayers = playersString.split(',')
    parsedPlayers.forEach((userId, index) => {
      const player = players.find(player => player.userId === userId)
      if (player) {
        const signedEloDifference = index === 0 ? eloDifference : -eloDifference
        if (player.eloDifference) {
          player.eloDifference += signedEloDifference
        } else {
          player.eloDifference = signedEloDifference
        }
      }
    })
  })

  for (const player of players) {
    if (game.playersToEloAtEndOfGame[player.userId] != null) {
      player.elo = game.playersToEloAtEndOfGame[player.userId]
    }
  }

  return players
}

class GameSummaryTable extends React.Component {


  render() {
    const {game} = this.props
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Player
            </TableCell>
            {
              [...Array(game.gameSettings.numberOfRounds)].map((_, index) => (
                <TableCell>
                  Round {index + 1}
                </TableCell>
              ))
            }
            <TableCell>
              Overall score
            </TableCell>
            <TableCell>
              Elo
            </TableCell>
            <TableCell className="rankIconCell"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            playersWithEloDifference(game)
              .sort((a, b) => game.playerScores.find(playerScore => playerScore.player === b.userId).score
                - game.playerScores.find(playerScore => playerScore.player === a.userId).score)
              .map((player, index) => (
                <TableRow>
                  <TableCell>
                    <div className='playerTableCell'>
                      <span className='positionLabel'>{index + 1}.</span>
                      <img src={`data:image/jpeg;base64,${player.picture}`} width={32} height={32} alt=''/>
                      <span className='usernameLabel'>{player.username}</span>
                    </div>
                  </TableCell>
                  {
                    [...Array(game.gameSettings.numberOfRounds)].map((_, index) => {
                      let guesses = game.guesses.find(guess => guess.player === player.userId)
                      let guessedCountry = getCountryInfo(guesses.guessedCountries[index])
                      return (
                        <TableCell>
                          <div className='roundSummaryTableCell'>
                          {game.gameSettings.gameMode === 'LOCATION' && <>{formatDistance(game.playersToDistances[player.userId][index])}<br/></>}
                          {formatPoints(game.playersToScores[player.userId][index])} <span className='extraPoints'>{game.gameSettings.extraPointsForCorrectCountry && game.playersToCountryCorrect[player.userId][index] ? ' +' + formatPoints(2000) : ''}</span>
                          {game.gameSettings.gameMode === 'COUNTRY' && <img className='roundSummaryTableCountryFlag' height={26} src={`https://flagsapi.com/${guessedCountry.countryCode2}/flat/32.png`} title={guessedCountry.displayName} />}
                          </div>
                        </TableCell>
                      )
                    })
                  }
                  <TableCell>
                    {formatPoints(game.playerScores.find(playerScore => playerScore.player === player.userId).score)}
                  </TableCell>
                  <TableCell>
                    {formatElo(player.elo)} {player.eloDifference > 0 && <span className='eloWon'>({numeral(player.eloDifference).format('+0,0')})</span>}
                    {player.eloDifference === 0 && <span>({numeral(player.eloDifference).format('+0,0')})</span>}
                    {player.eloDifference < 0 && <span className='eloLost'>({numeral(player.eloDifference).format('+0,0')})</span>}
                  </TableCell>
                  <TableCell>
                    {displayRankIcon(player.rankDefinition)}
                  </TableCell>
                </TableRow>
              ))
          }
        </TableBody>
      </Table>
    )
  }
}

export default GameSummaryTable