import React from 'react'
import './Player.scss'
import StarIcon from '@material-ui/icons/Star'
import PersonIcon from '@material-ui/icons/Person'
import UserContext from '../../context/UserContext'
import numeral from 'numeral'
import {displayRankIcon, findRank} from '../../helper/helperfunctions'

import appConf from '../../conf/app-conf.json'


const Player = props => {
  return (
    <UserContext.Consumer>
      {
        user => (
          props.player && user && (
            <>
              <div className={`player ${props.player.userId === user.userId && 'ownPlayer'}`}>
                <img src={`data:image/jpeg;base64,${props.player.picture}`} width={40} height={40} alt=""/>
                <span className="playerUsername">{props.player.username}</span>
                {props.isLobbyLeader && <StarIcon/>}
                <span className="lobbyPlayerElo">{numeral(props.player.elo).format('0,0')}</span>
                {displayRankIcon(props.player.rankDefinition)}
              </div>
              <div className="playerSeparator"/>
            </>
          )
        )
      }
    </UserContext.Consumer>
  )
}

export default Player