import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
import {mapCountryNamesToCodes} from '../../helper/helperfunctions'
import MapCountrySelection from '../Maps/MapCountrySelection'
import {streetViewCountries} from '../../static/StreetViewCountry'


const LobbyMapDialog = ({open, closeDialog, map, googleMaps, countriesGeoJson}) => (
  <Dialog open={open} onClose={closeDialog} maxWidth={false}>
    <DialogTitle>{map != null ? map.name : 'WORLD'}</DialogTitle>
    <DialogContent>
      <MapCountrySelection
        googleMaps={googleMaps}
        countriesGeoJson={countriesGeoJson}
        width={700}
        height={400}
        countries={map != null ? new Set(mapCountryNamesToCodes(map.countries)) : new Set(Object.keys(streetViewCountries))}
        editable={false}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={closeDialog} color='primary'>
        Close
      </Button>
    </DialogActions>
  </Dialog>
)

export default LobbyMapDialog