import React from 'react'
import Menu from '@material-ui/core/Menu/Menu'
import Button from '@material-ui/core/Button/Button'
import UserService from '../../service/UserService'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'
import {withRouter} from 'react-router'

import './UserMenu.scss'

class UserMenu extends React.Component {

  state = {
    anchorEl: null
  }

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget})
  }

  handleClose = () => {
    this.setState({anchorEl: null})
  }

  navigateToSettings = () => {
    this.props.history.push('/settings')
    this.handleClose()
  }

  handleLogout = () => {
    UserService.logout()
    this.handleClose()
    window.location.reload()
  }

  render() {
    return (
      <>
        <Button onClick={this.handleClick}>
          <img src={`data:image/jpeg;base64,${this.props.profilePicture}`} width={28} height={28} alt=''/>
          <span className='userMenu'>{this.props.username}</span>
        </Button>
        <Menu
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.navigateToSettings}>
            <SettingsIcon/>
            Settings
          </MenuItem>
          <MenuItem onClick={this.handleLogout}>
            <ExitToAppIcon/>
            Logout
          </MenuItem>
        </Menu>
      </>
    )
  }
}

export default withRouter(UserMenu)