import React from 'react'
import AppBar from '@material-ui/core/AppBar/AppBar'
import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import IconButton from '@material-ui/core/IconButton/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography/Typography'
import {withStyles} from '@material-ui/core/styles'
import UserService from '../../service/UserService'
import UserMenu from './UserMenu'

import './TitleBar.scss'

import appConf from '../../conf/app-conf'


const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  appBar: {
    zIndex: 2
  }
}

class TitleBar extends React.Component {

  state = {
    signedIn: null,
    userInfo: null
  }

  componentDidMount() {
    UserService.userInfo()
      .then(userInfo => {
        this.setState({
          signedIn: true,
          userInfo: userInfo
        })
      })
      .catch(err => this.setState({signedIn: false}))
  }

  login = () => {
    window.location.href = `${appConf.backendUrl}/templates/login.html`
  }

  render() {
    return (
      <AppBar position='fixed'>
        <Toolbar>

            <Typography variant="h6" color="inherit" className={this.props.classes.grow}>
              <div className='titleBarTitle'>
              {this.props.menuName}
              </div>
            </Typography>

          {
            this.state.signedIn ?
              <UserMenu
                profilePicture={this.state.userInfo.picture}
                username={this.state.userInfo.username}/> :
              <Button color="inherit" onClick={this.login.bind(this)}>Sign In</Button>
          }
        </Toolbar>
      </AppBar>
    )
  }
}

export default withStyles(styles)(TitleBar)