import React from 'react'
import Grid from '@material-ui/core/Grid'
import GameService from '../../service/GameService'
import OngoingGame from './OngoingGame'
import UserContext from '../../context/UserContext'
import './OngoingGamesOverview.scss'
import moment from 'moment'

import './OngoingGamesOverview.scss'


class OngoingGamesOverview extends React.Component {

  state = {
    loading: true
  }

  componentDidMount() {
    GameService.getGamesByFinished(false)
      .then(({games}) => this.setState({
        games,
        loading: false
      }))
  }

  render() {
    if (!this.state.games || this.state.games.length === 0) {
      return null
    }
    return (
      <div className="ongoingGamesOverview">
        <h2 className="homeTitle shadowText">Ongoing Games</h2>
        <Grid
          container
          alignItems="flex-start"
          spacing={8}
        >
          <UserContext.Consumer>
            {user => <>
              {[...this.state.games]
                .sort((a, b) => moment(b.started).unix() - moment(a.started).unix())
                .map(game =>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={4}
                    lg={3}
                    xl={3}
                  >
                    <div className="ongoingGameDiv">
                      <OngoingGame game={game} user={user}/>
                    </div>
                  </Grid>
                )}
            </>}
          </UserContext.Consumer>
        </Grid>
      </div>
    )
  }
}

export default OngoingGamesOverview