import appConf from '../conf/app-conf'

import axios from 'axios'

const newGame = (players, gameSettings, lobbyId, locations, countries) =>
  axios.post(`${appConf.backendUrl}/api/v1/game/new`, {
    players,
    gameSettings,
    lobbyId,
    locations,
    countries
  })
    .then(res => res.data)

const getGame = gameId =>
  axios.get(`${appConf.backendUrl}/api/v1/game/id/${gameId}`)
    .then(res => res.data)

const nextRound = gameId =>
  axios.get(`${appConf.backendUrl}/api/v1/game/nextround`, {
    params: {
      gameId
    }
  })
    .then(res => res.data)

const makeGuess = (gameId, round, location) =>
  axios.get(`${appConf.backendUrl}/api/v1/game/makeguess`, {
    params: {
      gameId,
      round,
      lat: location.lat,
      lng: location.lng
    }
  })

const makeCountryGuess = (gameId, round, country) =>
  axios.get(`${appConf.backendUrl}/api/v1/game/makecountryguess`, {
    params: {
      gameId,
      round,
      country
    }
  })

const setReadyForRound = (gameId, round) =>
  axios.get(`${appConf.backendUrl}/api/v1/game/ready`, {
    params: {
      gameId,
      round
    }
  })

const getGames = () => axios.get(`${appConf.backendUrl}/api/v1/game/all`).then(res => res.data)

const getGamesByFinished = (finished, offset, limit) => {
  const params = {
    finished
  }
  if (offset != null) {
    params.offset = offset
  }
  if (limit != null) {
    params.limit = limit
  }
  return axios.get(`${appConf.backendUrl}/api/v1/game/all`, {params})
    .then(res => ({games: res.data, total: parseInt(res.headers['x-total-count'])}))
}


const getGameIdsByFinished = finished =>
  axios.get(`${appConf.backendUrl}/api/v1/game/ids`, {
    params: {
      finished
    }
  })
    .then(res => res.data)


export default {
  newGame,
  getGame,
  nextRound,
  setReadyForRound,
  makeGuess,
  makeCountryGuess,
  getGames,
  getGamesByFinished,
  getGameIdsByFinished
}