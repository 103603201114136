import numeral from 'numeral'
import ranks from '../static/ranks.json'
import appConf from '../conf/app-conf.json'
import React from 'react'
import {streetViewCountries} from '../static/StreetViewCountry'

const roundWithDecimalDigits = (number, digits) => Math.round(Math.pow(10, digits) * number) / Math.pow(10, digits)

const formatDistance = distance => {
  const format = '0,0.0'
  if (distance > 2) {
    return `${numeral(distance).format(format)} km`
  }
  return `${numeral(1000 * distance).format(format)} m`
}

const formatPoints = points => {
  const format = '0,0'
  return numeral(points).format(format)
}

const formatElo = elo => {
  const format = '0,0'
  return numeral(elo).format(format)
}

const findRank = name => ranks.find(rank => rank.name === name)

const displayRankIcon = rankDefinition => {
  let rank = findRank(rankDefinition && rankDefinition.name)
  if (rank == null) {
    return <span/>;
  }
  return <img src={`${appConf.backendUrl}/${rank && rank.img}`} width={36} height={36} alt=""/>
}

const randomInt = (from, to) => Math.floor((to - from) * Math.random()) + from

const randomFloat = (from, to) => (to - from) * Math.random() + from

const transformWeightVector = (weights, func) => {
  let sum = 0
  let result = []
  for (let i = 0; i < weights.length; i++) {
    let elm = func(weights[i])
    result.push(elm)
    sum += elm
  }
  for (let i = 0; i < result.length; i++) {
    result[i] /= sum
  }
  return result
}

const randomIndexFromWeightVector = weights => {
  let random = Math.random()
  for (let i = 0; i < weights.length; i++) {
    random -= weights[i]
    if (random < 0) {
      return i
    }
  }
}

const mapCountryCodesToNames = countryIds =>
  countryIds
    .filter(countryCode => streetViewCountries[countryCode] != null)
    .map(countryCode => streetViewCountries[countryCode].name)

const mapCountryNamesToCodes = countryNames => {
  let nameToId = {}
  for (const [countryCode, country] of Object.entries(streetViewCountries)) {
    nameToId[country.name] = country.countryCode3
  }

  return countryNames.filter(name => nameToId[name] != null).map(name => nameToId[name])
}

const getCountryInfo = countryName => {
  for (const [countryCode, country] of Object.entries(streetViewCountries)) {
    if (countryName === country.name) {
      return country
    }
  }
}

export {
  formatDistance,
  formatPoints,
  formatElo,
  roundWithDecimalDigits,
  findRank,
  displayRankIcon,
  randomInt,
  randomFloat,
  transformWeightVector,
  randomIndexFromWeightVector,
  mapCountryCodesToNames,
  mapCountryNamesToCodes,
  getCountryInfo
}