import React from 'react'
import ReactDOM from 'react-dom'
import isEqual from 'lodash.isequal'


const markerIconSize = 24

const degToRad = deg => deg / 180 * Math.PI

const radToDeg = rad => rad / Math.PI * 180

const latLngToCartestianCoordinates = latLng => ({
  x: Math.cos(degToRad(latLng.lng)) * Math.cos(degToRad(latLng.lat)),
  y: Math.sin(degToRad(latLng.lng)) * Math.cos(degToRad(latLng.lat)),
  z: Math.sin(degToRad(latLng.lat))
})

const cartesianCoordinatesToLatLng = coordinates => ({
  lat: radToDeg(Math.asin(coordinates.z)),
  lng: radToDeg(Math.atan(coordinates.y / coordinates.x))
    + (coordinates.y > 0 && coordinates.x < 0 ? 180 : 0)
    - (coordinates.y < 0 && coordinates.x < 0 ? 180 : 0)
})

const scalarVector3Multiplication = (scalar, vector3) => ({
  x: scalar * vector3.x,
  y: scalar * vector3.y,
  z: scalar * vector3.z
})

const vector3Addition = (a, b) => ({
  x: a.x + b.x,
  y: a.y + b.y,
  z: a.z + b.z
})

const norm = vector => Math.sqrt(Object.entries(vector).reduce((sum, [k, v]) => sum + v * v, 0))

const normalize = vector => scalarVector3Multiplication(1 / norm(vector), vector)

const vector3Lerp = (a, b, t) => vector3Addition(scalarVector3Multiplication((1 - t), a), scalarVector3Multiplication(t, b))

const shortestPath = (a, b) => {
  const path = [a]
  const cartesianA = latLngToCartestianCoordinates(a)
  const cartesianB = latLngToCartestianCoordinates(b)
  const iterations = 200
  for (let i = 1; i < iterations; i++) {
    path.push(cartesianCoordinatesToLatLng(normalize(vector3Lerp(cartesianA, cartesianB, i / iterations))))
  }
  path.push(b)
  return path
}

class MakeGuessMapView extends React.Component {

  initializeCorrectLocationsMarkers(map) {
    this.correctLocationMarkers = []

    this.props.correctLocations.forEach(location => {
      const correctLocationMarker = new this.props.googleMaps.Marker({
        position: location,
        map
      })
      correctLocationMarker.addListener('click', () => {
        window.open(`https://www.google.com/maps?q&layer=c&cbll=${location.lat},${location.lng}`, '_blank')
      })
      this.correctLocationMarkers.push(correctLocationMarker)
    })
  }

  initializePlayerMarkersWithPaths(map) {
    this.markers = []
    this.pathsToCorrectLocation = []
    this.props.correctLocations.forEach((location, index) => {
      const markers = this.props.playerGuesses[index].map(playerGuess => new this.props.googleMaps.Marker({
        position: playerGuess.location,
        map: this.map,
        icon: {
          url: `data:image/jpeg;base64,${playerGuess.player.picture}`,
          scaledSize: new this.props.googleMaps.Size(markerIconSize, markerIconSize),
          anchor: new this.props.googleMaps.Point(Math.floor(markerIconSize / 2), Math.floor(markerIconSize / 2))
        }
      }))
      const pathsToCorrectLocation = this.props.playerGuesses[index].map(playerGuess => new this.props.googleMaps.Polyline({
        clickable: false,
        map: this.map,
        path: shortestPath(this.props.correctLocations[index], playerGuess.location),
        strokeOpacity: 0,
        icons: [{
          icon: {
            path: 'M 0,-1 0,1',
            strokeOpacity: 0.6,
            scale: 3
          },
          offset: '10',
          repeat: '20px'
        }]
      }))
      this.markers.push(markers)
      this.pathsToCorrectLocation.push(pathsToCorrectLocation)
    })
  }

  initialize(canvas) {
    if (!this.map) {
      this.map = new this.props.googleMaps.Map(
        canvas, {
          center: this.props.correctLocations.length === 1 ? this.props.correctLocations[0] : {lat: 0, lng: 0},
          zoom: this.props.correctLocations.length === 1 ? 4 : 1,
          clickableIcons: false,
          streetViewControl: false,
          mapTypeControl: false,
          mapTypeControlOptions: {
            mapTypeIds: ['roadmap']
          }
        }
      )

      this.initializeCorrectLocationsMarkers(this.map)
      if (this.props.playerGuesses != null) {
        this.initializePlayerMarkersWithPaths(this.map)
      }
    }
  }

  componentDidMount() {
    this.initialize(ReactDOM.findDOMNode(this))
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.playerGuesses, this.props.playerGuesses)) {
      this.props.playerGuesses.forEach((playerGuesses, index1) => {
        playerGuesses.forEach((playerGuess, index2) => {
          if (playerGuess.location) {
            if (this.markers[index1][index2]) {
              this.markers[index1][index2].setPosition(playerGuess.location)
            }
            if (this.pathsToCorrectLocation[index1][index2]) {
              this.pathsToCorrectLocation[index1][index2].setPath(shortestPath(playerGuess.location, this.props.correctLocations[index1]))
            }
          }
        })
      })
    }
  }

  render() {
    return <div
      style={{
        height: `${this.props.height}px`
      }}
    ></div>
  }
}

export default MakeGuessMapView