import React from 'react'
import queryString from 'query-string'
import LobbyService from '../../service/LobbyService'
import CountrySelectionService from '../../service/CountrySelectionService'
import ClearIcon from '@material-ui/icons/Clear'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import {withRouter} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid/Grid'
import PlayersOverview from './PlayersOverview'
import GameSettingsHost from './GameSettingsHost'
import UserContext from '../../context/UserContext'
import './Lobby.scss'
import TextField from '@material-ui/core/TextField/TextField'
import IconButton from '@material-ui/core/IconButton/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import CloseIcon from '@material-ui/icons/Close'
import appConf from '../../conf/app-conf'
import {Card, CardContent, InputLabel} from '@material-ui/core'
import GameSettingsGuest from './GameSettingsGuest'
import axios from 'axios'


let lobbyRefetch

class Lobby extends React.Component {

  constructor(props) {
    super(props)
    const queryStringData = queryString.parse(window.location.search)
    this.state = {
      lobbyId: queryStringData.lobbyId,
      lobby: {
        gameSettings: {
          gameMode: 'LOCATION',
          numberOfRounds: 5,
          timePerRound: 120,
          movingAllowed: true,
          withCompass: true,
          locationGenerationType: 'BY_COUNTRY_SELECTION'
        }
      },
      availableMaps: []
    }
  }

  componentDidMount() {
    if (this.state.lobbyId) {
      this.retrieveLobby(this.state.lobbyId, true)
      lobbyRefetch = setInterval(() => this.retrieveLobby(this.state.lobbyId, false), 1000)
    } else {
      LobbyService.retrieveLobbiesOfUser()
        .then(lobbies => {
          if (lobbies.length === 0) {
            LobbyService.postNewLobby(`${Math.ceil(68 * Math.random())}.jpg`).then(lobby => {
              CountrySelectionService.myMaps()
                .then(maps => {
                  this.setState({
                    lobby,
                    lobbyId: lobby.lobbyId,
                    availableMaps: maps
                  })
                  this.props.setBackgroundImage(lobby.background)
                  this.props.history.push(`/lobby?lobbyId=${lobby.lobbyId}`)
                })
            })
          } else {
            const lobbyId = lobbies[0].lobbyId
            this.props.history.push(`/lobby?lobbyId=${lobbyId}`)
            this.retrieveLobby(lobbyId, true)
          }
          lobbyRefetch = setInterval(() => this.retrieveLobby(this.state.lobbyId, false), 1000)
        })
    }
  }

  componentWillUnmount() {
    clearInterval(lobbyRefetch)
  }

  retrieveLobby = (lobbyId, initialLoad=true) => {
    LobbyService.retrieveLobby(lobbyId)
      .then(lobby => {
        CountrySelectionService.myMaps()
          .then(maps => {
            this.setState({availableMaps: maps})
          })
        this.props.setBackgroundImage(lobby.background)
        if (lobby.gameId) {
          clearInterval(lobbyRefetch)
          this.props.history.push(`/game?gameId=${lobby.gameId}`)
        } else if (!this.isLobbyLeader(this.props.user) || initialLoad) {
          this.setState({lobbyId, lobby})
        } else {
          let newLobby = {...this.state.lobby}
          newLobby.players = lobby.players
          this.setState({lobby: newLobby})
        }
      })
  }

  leaveLobby = () => {
    if (lobbyRefetch != null) {
      clearInterval(lobbyRefetch)
    }
    LobbyService.leaveLobby(this.state.lobbyId)
      .then(_ => this.props.history.push('/'))

  }

  changeGameSettings = gameSettings => {
    const lobby = {...this.state.lobby}
    lobby.gameSettings = gameSettings
    LobbyService.updateGameSettings(this.state.lobbyId, gameSettings)
      .then(lobby => this.setState({lobby}))
    this.setState({lobby})
  }

  isLobbyLeader = user => !!user && !!this.state.lobby && !!this.state.lobby.lobbyLeader &&
    user.userId === this.state.lobby.lobbyLeader.userId

  openSnackbar() {
    this.setState({snackbarOpen: true})
  }

  closeSnackbar() {
    this.setState({snackbarOpen: false})
  }

  numberOfRoundsIsValid = () => this.state.lobby.gameSettings.numberOfRounds > 0

  timePerRoundIsValid = () => this.state.lobby.gameSettings.timePerRound >= 5

  gameSettingsAreValid = () => (
    this.numberOfRoundsIsValid() && this.timePerRoundIsValid()
  )

  render() {
    if (!this.state.lobby || !this.state.lobby.players) {
      return null
    }
    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={4000}
          onClose={this.closeSnackbar.bind(this)}
          message={<span id="message-id">Invite link copied to clipboard</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.closeSnackbar.bind(this)}
            >
              <CloseIcon/>
            </IconButton>
          ]}
        >
        </Snackbar>
        <Card className="inviteLinkCard shadowBox transparentBackground">
          <CardContent className="inviteLinkCardContent">
            <div className="inviteLink">
              <div className="inviteLinkTextField">
                <InputLabel>
                  Invite link
                </InputLabel>
                <TextField
                  id="invite-link"
                  fullWidth
                  value={`${appConf.frontendUrl}/lobby?lobbyId=${this.state.lobby.lobbyId}`}
                />
              </div>
              <div className="copyToClipboardButton">
                <CopyToClipboard
                  onCopy={this.openSnackbar.bind(this)}
                  text={`${appConf.frontendUrl}/lobby?lobbyId=${this.state.lobby.lobbyId}`}>
                  <IconButton>
                    <FileCopyIcon/>
                  </IconButton>
                </CopyToClipboard>
              </div>
            </div>
          </CardContent>
        </Card>
        <Grid
          container
          alignItems="flex-start"
          spacing={24}>
          <Grid
            item
            key="players"
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}>
            <PlayersOverview players={this.state.lobby && this.state.lobby.players} lobby={this.state.lobby}/>
          </Grid>
          <Grid
            item
            key="settings"
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}>
            {
              this.state.lobby && this.props.user && this.isLobbyLeader(this.props.user) &&
              <GameSettingsHost
                gameSettings={this.state.lobby.gameSettings}
                onChange={this.changeGameSettings.bind(this)}
                numberOfRoundsIsValid={this.numberOfRoundsIsValid()}
                timePerRoundIsValid={this.timePerRoundIsValid()}
                availableMaps={this.state.availableMaps}
                googleMaps={this.props.googleMaps}
                countriesGeoJson={this.props.countriesGeoJson}
              />
            }
            {
              this.state.lobby && this.props.user && !this.isLobbyLeader(this.props.user) &&
              <GameSettingsGuest
                gameSettings={this.state.lobby.gameSettings}
                googleMaps={this.props.googleMaps}
                countriesGeoJson={this.props.countriesGeoJson}
              />
            }
          </Grid>
        </Grid>
        <div className="lobbyControl">
          <Button className="shadowBox" variant="contained" color="secondary" onClick={this.leaveLobby}>
            <ClearIcon/>
            <span className="actionButtonText">Leave lobby</span>
          </Button>
          {this.isLobbyLeader(this.props.user) &&
            <Button className="shadowBox" variant="contained" color="primary" disabled={!this.gameSettingsAreValid()}
                    onClick={() => {
                      this.props.startNewGame(this.state.lobby.players, this.state.lobby.gameSettings, this.state.lobby.lobbyId)
                    }}>
              <PlayArrowIcon/>
              <span className="actionButtonText">Start game</span>
            </Button>}
        </div>
      </>

    )
  }
}

export default withRouter(Lobby)