import appConf from '../conf/app-conf'

import axios from 'axios'

const myMaps = () =>
  axios.get(`${appConf.backendUrl}/api/v1/countryselection/my`)
    .then(res => res.data)

const createNewMap = mapSubmission =>
  axios.post(`${appConf.backendUrl}/api/v1/countryselection/new`, mapSubmission)
    .then(res => res.data)

const updateMap = (countrySelectionId, mapSubmission) =>
  axios.put(`${appConf.backendUrl}/api/v1/countryselection/id/${countrySelectionId}`, mapSubmission)
    .then(res => res.data)

export default {myMaps, createNewMap, updateMap}