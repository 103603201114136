import React from 'react'
import './GamePlayersPreview.scss'
import Grid from '@material-ui/core/Grid'


const getResultsToShow = (game, user) => {
  const result = [...game.playerScores].sort((a, b) => b.score - a.score)
  if (result.length === 0) return []

  let resultToShow = []
  if (result.length <= 2) {
    resultToShow = result.map((res, index) => ({...res, position: index + 1}))
  } else {
    resultToShow[0] = {...result[0], position: 1}
    if (resultToShow[0].player === user.userId) {
      resultToShow[1] = {...result[1], position: 2}
    } else {
      let playerIndex
      for (let i = 0; i < result.length; i++) {
        if (result[i].player === user.userId) {
          playerIndex = i
          break
        }
      }
      if (playerIndex != null) {
        resultToShow[1] = {...result[playerIndex], position: playerIndex + 1}
      }
    }
  }
  return resultToShow
}

const GamePlayersPreview = ({game, user}) => {
  if (!user) return null

  return <>
    {getResultsToShow(game, user).map(playerScore => {
      const player = game.players.find(player => player.userId === playerScore.player)
      if (!player) {
        return null
      }
      return (
        <Grid
          container
          alignItems='flex-start'
          spacing={8}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={9}
            xl={9}
          >
            <div className='playersPreview'>
              <div className='playersPreviewPosition'>{playerScore.position}.</div>
              <div className='playersPreviewPicture'>
                <img src={`data:image/jpeg;base64,${player.picture}`} width={16} height={16} alt={''}/>
              </div>
              <div className='playersPreviewUsername'>{player.username}</div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={3}
            xl={3}
          >
            <span>{playerScore.score}</span>
          </Grid>
        </Grid>
      )
    })}
  </>
}

export default GamePlayersPreview