import React from 'react'
import LobbyService from '../../service/LobbyService'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'
import Button from '@material-ui/core/Button'
import {Link} from 'react-router-dom'
import OngoingGamesOverview from './OngoingGamesOverview'
import './Home.scss'
import PastGamesOverview from './PastGamesOverview'
import UserContext from '../../context/UserContext'
import numeral from 'numeral'
import appConf from '../../conf/app-conf'
import {findRank, formatElo} from '../../helper/helperfunctions'
import {v4 as uuid} from 'uuid'


class Home extends React.Component {

  state = {
    lobbiesOfUser: []
  }

  componentDidMount() {
    LobbyService.retrieveLobbiesOfUser()
      .then(lobbiesOfUser => this.setState({lobbiesOfUser}))
  }

  actionButton = isInLobby => {
    if (isInLobby) {
      return (
        <>
          <KeyboardReturnIcon/>
          <span className='actionButtonText'>Return to lobby</span>
        </>
      )
    } else {
      return (
        <>
          <AddCircleOutlineIcon/>
          <span className='actionButtonText'>New Lobby</span>
        </>
      )
    }
  }

  render() {
    return (
      <div className='home'>
        <h1 className='title shadowText'>Globe Guesser</h1>
        {
          this.props.loggedIn &&
            <UserContext.Consumer>
              {user => {
                let rank = findRank(user.rankDefinition && user.rankDefinition.name)
                return (
                  <div className='homeComponent' key={uuid()}>
                    {rank && rank.img ? (
                      <img src={`${appConf.backendUrl}/${rank.img}`} width={128} height={128} alt=""/>
                    ) : (
                      <div style={{height: 128}} />
                    )}
                    <div className={"rankDisplayName"}>
                      <h3 className='homeHeading shadowText'>{rank != null ? rank.displayName : 'Your Elo'}</h3>
                    </div>
                    <h2 className='homeHeading shadowText'>{formatElo(user.elo)}</h2>
                  </div>
                )
              }}
            </UserContext.Consumer>
        }
        {
          this.props.loggedIn ?
            <Link to='/lobby'>
              <Button className='shadowBox' variant='contained' color='primary'>
                {this.actionButton(this.state.lobbiesOfUser.length > 0)}
              </Button>
            </Link> :
            <Button className='shadowBox'
                    color='primary'
                    variant='contained'
                    onClick={() => window.location.href = `${appConf.frontendUrl}/templates/login.html`}>
              Sign In
            </Button>
        }
        {
          this.props.loggedIn &&
          <>
            <div className='homeComponent'>
              <OngoingGamesOverview/>
            </div>
            <div className='homeComponent'>
              <PastGamesOverview/>
            </div>
          </>
        }
      </div>
    )
  }
}

export default Home