import React from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRunning, faCompass} from '@fortawesome/free-solid-svg-icons'

const formatTime = seconds => {
  const minutes = Math.floor(seconds / 60)
  const secondsOfMinute = seconds % 60;
  return `${minutes}:${Math.floor(secondsOfMinute / 10)}${secondsOfMinute % 10}`
}

const GameStatus = ({movingAllowed, withCompass, roundNumber, totalRounds, timeRemaining}) => {
  return (
    <Grid
      container
      spacing={4}
    >
      <Grid
        item
        xs={3}
        sm={3}
        md={3}
        lg={3}
        xl={3}
      >
        <FontAwesomeIcon icon={faRunning} color={movingAllowed ? 'green': 'red'}/>
      </Grid>
      <Grid
        item
        xs={3}
        sm={3}
        md={3}
        lg={3}
        xl={3}
      >
        <FontAwesomeIcon icon={faCompass} color={withCompass ? 'green' : 'red'}/>
      </Grid>
      <Grid
        item
        xs={3}
        sm={3}
        md={3}
        lg={3}
        xl={3}
      >
        {roundNumber}/{totalRounds}
      </Grid>
      <Grid
        item
        xs={3}
        sm={3}
        md={3}
        lg={3}
        xl={3}
      >
        {timeRemaining != null ? formatTime(timeRemaining) : ''}
      </Grid>

    </Grid>
  )
}

export default GameStatus