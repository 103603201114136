import React from 'react'
import ReactDOM from 'react-dom'
import isEqual from 'lodash.isequal'


class MakeGuessMapView extends React.Component {

  initialize(canvas) {
    if (!this.map) {
      this.map = new this.props.googleMaps.Map(
        canvas, {
          center: {lat: 0, lng: 0},
          zoom: 1,
          clickableIcons: false,
          streetViewControl: false,
          mapTypeControl: false,
          mapTypeControlOptions: {
            mapTypeIds: ['roadmap']
          },
          draggableCursor: 'crosshair'
        }
      )

      this.marker = new this.props.googleMaps.Marker({
        position: this.props.markerLocation,
        map: this.map
      })

      this.map.addListener('click', event => {
        this.props.setMarker({
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        })
      })
    }
  }

  componentDidMount() {
    this.initialize(ReactDOM.findDOMNode(this))
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.markerLocation, this.props.markerLocation)) {
      this.marker.setPosition(this.props.markerLocation)
    }
  }

  render() {
    return <div
      style={{
        height: `${this.props.height}px`,
        width: `${this.props.width}px`
      }}
    ></div>
  }
}

export default MakeGuessMapView