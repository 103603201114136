import React from 'react'
import List from '@material-ui/core/List/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import HomeIcon from '@material-ui/icons/Home'
import PeopleIcon from '@material-ui/icons/People'
import AddLocationIcon from '@material-ui/icons/AddLocation'
import TableChartIcon from '@material-ui/icons/TableChart'
import LanguageIcon from '@material-ui/icons/Language'
import TimelineIcon from '@material-ui/icons/Timeline'
import MapIcon from '@material-ui/icons/Map'
import {withRouter} from 'react-router-dom'

import './NavDrawerContent.scss'
import {Icon} from '@material-ui/core'


const menuOptions = {
  home: {
    text: 'Home',
    icon: () => <HomeIcon/>,
    linkTo: '/'
  },
  lobby: {
    text: 'Lobby',
    icon: () => <PeopleIcon/>,
    linkTo: '/lobby'
  },
  game: {
    text: 'Game',
    icon: () => <AddLocationIcon/>,
    linkTo: '/game'
  },
  ranking: {
    text: 'Ranking',
    icon: () => <TableChartIcon/>,
    linkTo: '/ranking'
  },
  stats: {
    text: 'Stats',
    icon: () => <TimelineIcon/>,
    linkTo: '/stats'
  },
  maps: {
    text: 'Maps',
    icon: () => <MapIcon/>,
    linkTo: '/maps'
  }
}

const NavDrawerContent = props => {

  const onClickNavItem = linkTo => () => {
    props.history.push(linkTo)
    props.closeNavDrawer()
  }

  return (
    <div>
      <div className="appIcon">
        <LanguageIcon style={{fontSize: 48, color: 'blue'}}/>
      </div>
      <List>
        {
          Object.entries(menuOptions).map(([key, {text, icon, linkTo}]) => (
            <ListItem
              button key={key}
              onClick={onClickNavItem(linkTo)}
              disabled={key === 'maps' && props.countriesGeoJson == null}
            >
              <ListItemIcon>{icon()}</ListItemIcon>
              <ListItemText primary={text}/>
            </ListItem>
          ))
        }
      </List>
    </div>
  )
}

export default withRouter(NavDrawerContent)