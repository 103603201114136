import React from 'react'
import CardContent from '@material-ui/core/CardContent/CardContent'
import Card from '@material-ui/core/Card/Card'
import TextField from '@material-ui/core/TextField/TextField'
import Switch from '@material-ui/core/Switch/Switch'
import {Button, InputLabel, MenuItem, Select} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import './GameSettingsHost.scss'
import LobbyMapDialog from './LobbyMapDialog'


class GameSettingsHost extends React.Component {

  state = {
    mapDialogOpen: false
  }

  changeGameMode = event => {
    const gameMode = event.target.value
    this.props.onChange({...this.props.gameSettings, gameMode, extraPointsForCorrectCountry: false})
  }

  changeNumberOfRounds = event => {
    const numberOfRounds = event.target.value
    this.props.onChange({...this.props.gameSettings, numberOfRounds})
  }

  changeTimePerRound = event => {
    const timePerRound = event.target.value
    this.props.onChange({...this.props.gameSettings, timePerRound})
  }

  changeMovingAllowed = event => {
    this.props.onChange({...this.props.gameSettings, movingAllowed: event.target.checked})
  }

  changeWithCompass = event => {
    this.props.onChange({...this.props.gameSettings, withCompass: event.target.checked})
  }

  changeExtraPointsForCorrectCountry = event => {
    this.props.onChange({...this.props.gameSettings, extraPointsForCorrectCountry: event.target.checked})
  }

  changeLocationGenerationType = event => {
    this.props.onChange({...this.props.gameSettings, locationGenerationType: event.target.value})
  }

  changeMap = event => {
    this.props.onChange({
      ...this.props.gameSettings,
      countrySelection: event.target.value === 'WORLD'
        ? undefined
        : this.props.availableMaps.find(map => map.id === event.target.value)
    })
  }

  openMapDialog = () => {
    this.setState({mapDialogOpen: true})
  }

  closeMapDialog = () => {
    this.setState({mapDialogOpen: false})
  }

  render() {
    return (
      <>
        <LobbyMapDialog
          open={this.state.mapDialogOpen}
          closeDialog={this.closeMapDialog}
          map={this.props.gameSettings.countrySelection}
          googleMaps={this.props.googleMaps}
          countriesGeoJson={this.props.countriesGeoJson}
        />
        <Card className="gameSettingsHostCard shadowBox transparentBackground">
          <CardContent>
            <div className="gameSettingsHost">
              <div className="gameSettingsHostRow">
                <InputLabel>
                  Game mode
                </InputLabel>
                <Select
                  value={this.props.gameSettings.gameMode}
                  color="primary"
                  onChange={this.changeGameMode}
                  fullWidth
                >
                  <MenuItem value="LOCATION">Location</MenuItem>
                  <MenuItem value="COUNTRY">Country only</MenuItem>
                </Select>
              </div>
              <div className="gameSettingsHostRow">
                <InputLabel>
                  Rounds per game
                </InputLabel>
                <TextField
                  id="rounds-per-game"
                  type="number"
                  value={this.props.gameSettings.numberOfRounds}
                  onChange={this.changeNumberOfRounds}
                  error={!this.props.numberOfRoundsIsValid}
                  helperText={this.props.numberOfRoundsIsValid ? '' : 'Number of rounds needs to be at least 1.'}
                />
              </div>
              <div className="gameSettingsHostRow">
                <InputLabel>
                  Time per round (sec)
                </InputLabel>
                <TextField
                  id="timePerRound"
                  type="number"
                  value={this.props.gameSettings.timePerRound}
                  onChange={this.changeTimePerRound}
                  error={!this.props.timePerRoundIsValid}
                  helperText={this.props.timePerRoundIsValid ? '' : 'Time per round needs to be at least 5s.'}
                />
              </div>
              <div className="gameSettingsHostRow">
                <div className="gameSettingsGuestSwitchRow">
                  <Switch
                    checked={this.props.gameSettings.movingAllowed}
                    color="primary"
                    onChange={this.changeMovingAllowed}
                  />
                  <div>Moving allowed</div>
                </div>
              </div>
              <div className="gameSettingsHostRow">
                <div className="gameSettingsGuestSwitchRow">
                  <Switch
                    checked={this.props.gameSettings.withCompass}
                    color="primary"
                    onChange={this.changeWithCompass}
                  />
                  <div>Compass</div>
                </div>
              </div>
              <div className="gameSettingsHostRow">
                <div className="gameSettingsGuestSwitchRow">
                  <Switch
                    checked={this.props.gameSettings.extraPointsForCorrectCountry}
                    color="primary"
                    disabled={this.props.gameSettings.gameMode === 'COUNTRY'}
                    onChange={this.changeExtraPointsForCorrectCountry}
                  />
                  <div>Extra points for correct country</div>
                </div>
              </div>
              <div className="gameSettingsHostRow">
                <InputLabel>
                  Location generation
                </InputLabel>
                <Select
                  value={this.props.gameSettings.locationGenerationType}
                  color="primary"
                  onChange={this.changeLocationGenerationType}
                  fullWidth
                >
                  <MenuItem value="BY_COUNTRY_SELECTION">By country selection</MenuItem>
                  <MenuItem value="BY_AREA">By area</MenuItem>
                  <MenuItem value="US_BY_POPULATION">US by population</MenuItem>
                </Select>
              </div>
              {
                this.props.gameSettings.locationGenerationType === 'BY_COUNTRY_SELECTION' && (
                  <div className={"gameSettingsHostMap"}>
                    <div className={this.props.gameSettings.countrySelection == null ? 'gameSettingsHostRow' : 'gameSettingsHostRowMap'}>
                      <InputLabel>
                        Map
                      </InputLabel>
                      <Select
                        value={this.props.gameSettings.countrySelection ? this.props.gameSettings.countrySelection.id : 'WORLD'}
                        color="primary"
                        fullWidth
                        onChange={this.changeMap}
                      >
                        <MenuItem value="WORLD">WORLD</MenuItem>
                        {this.props.availableMaps && this.props.availableMaps.map(map => (
                          <MenuItem value={map.id}>{map.name}</MenuItem>
                        ))
                        }
                      </Select>
                    </div>
                    <div className="gameSettingsHostMapInfoButtonContainer">
                      <Button className="gameSettingsGuestMapInfoButton" onClick={this.openMapDialog} >
                        <InfoOutlinedIcon/>
                      </Button>
                    </div>
                  </div>
                )
              }
            </div>
          </CardContent>
        </Card>
      </>
    )
  }
}

export default GameSettingsHost