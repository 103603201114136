import React from 'react'
import './Scoreboard.scss'
import ScoreboardEntry from './ScoreboardEntry'


const Scoreboard = props => {
  const {players, roundsCompleted, playerScores, locations} = props

  return (
    <>
      {
        [...playerScores].sort((a, b) => b.score - a.score).map((playerScore, index) => {
          let numberOfGuesses = roundsCompleted.find(guess => guess.player === playerScore.player)
          return (
            <div className='scoreboardEntry'>
              <ScoreboardEntry
                position={index + 1}
                player={players.find(player => player.userId === playerScore.player)}
                numberOfGuesses={numberOfGuesses ? numberOfGuesses.guesses : 0}
                numberOfLocations={locations.length}
                score={playerScore.score}
              />
            </div>
          )
        })
      }
    </>
  )
}

export default Scoreboard