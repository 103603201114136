import React from 'react'
import {CardContent} from '@material-ui/core'
import moment from 'moment'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCompass, faProjectDiagram, faRunning} from '@fortawesome/free-solid-svg-icons'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import {withRouter} from 'react-router'
import GamePlayersPreview from './GamePlayersPreview'
import './PastGame.scss'


const PastGame = ({history, game, user}) => {
  if (!game || !user) {
    return null
  }
  const openGameSummary = () => history.push(`/gamesummary?gameId=${game.gameId}`)

  return (
    <Card className="shadowBox transparentBackground">
      <CardContent>
        <div className="pastGame">
          {moment(game.started).format('lll')}<br/>
          {game.locations.length} {game.locations.length === 1 ? 'round' : 'rounds'}<br/>
          <div className="pastGameRow">
            <FontAwesomeIcon icon={faRunning} color={game.gameSettings.movingAllowed ? 'green' : 'red'}/>
            <span className="nextToIconLabel">{game.gameSettings.movingAllowed ? 'Moving' : 'No moving'}</span>
          </div>
          <div className="pastGameRow">
            <FontAwesomeIcon icon={faCompass} color={game.gameSettings.withCompass ? 'green' : 'red'}/>
            <span className="nextToIconLabel">{game.gameSettings.withCompass ? 'Compass' : 'No compass'}</span>
          </div>
          <div className="pastGamePlayersPreview">
            <GamePlayersPreview game={game} user={user}/>
          </div>
          <div className="pastGameSpace"/>
          <Button color="primary" onClick={openGameSummary}>
            <FontAwesomeIcon icon={faProjectDiagram}/>
            <span className="nextToIconLabel">View Summary</span>
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}

export default withRouter(PastGame)