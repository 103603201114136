import React from 'react'
import ReactDOM from 'react-dom'
import appConf from './conf/app-conf'


function BackToStartControl(controlDiv, map, startPosition) {
  // Set CSS for the control border.
  const controlUI = document.createElement('div')
  controlUI.style.backgroundColor = 'rgba(34, 34, 34, 1)'
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)'
  controlUI.style.borderRadius = '2px'
  controlUI.style.cursor = 'pointer'
  controlUI.style.marginRight = '10px'
  controlUI.style.marginBottom = '16px'
  controlUI.style.textAlign = 'center'
  controlUI.title = 'Click to go back to start'
  controlDiv.appendChild(controlUI)

  // Set CSS for the control interior.
  const controlIcon = document.createElement('img')
  controlIcon.style.color = 'rgb(25,25,25)'
  controlIcon.style.paddingLeft = '6px'
  controlIcon.style.paddingRight = '6px'
  controlIcon.style.paddingTop = '7px'
  controlIcon.style.paddingBottom = '6px'
  controlIcon.src = `${appConf.frontendUrl}/map-marker-icon-png-24-64x64.png`
  controlIcon.width = 30
  controlUI.appendChild(controlIcon)

  controlUI.addEventListener('click', function () {
    map.setPosition(startPosition)
  })
}

class ReactStreetview extends React.Component {

  constructor(props) {
    super(props)
    this.streetView = null
    this.state = {
      positionSet: false,
      initialPosition: null,
    }
  }

  initialize(canvas) {
    const {googleMaps, movingAllowed, zoomingAllowed, withCompass, position, zoom} = this.props
    if (googleMaps && this.streetView == null) {
      this.streetView = new googleMaps.StreetViewPanorama(
        canvas,
        {
          clickToGo: !!movingAllowed,
          disableDoubleClickZoom: !zoomingAllowed,
          disableDefaultUI: true,
          fullscreenControl: true,
          imageDateControl: false,
          scrollWheel: !!zoomingAllowed,
          showRoadLabels: false,
          zoomControl: !!zoomingAllowed,
          panControl: !!withCompass,
          keyboardShortcuts: false,
          linksControl: !!movingAllowed,
          pov: {
            heading: 360 * Math.random(),
            pitch: 0
          },
          position,
          zoom,
          motionTracking: false,
        }
      )

      this.streetView.addListener('position_changed', () => {
        if (!movingAllowed && this.state.positionSet && (
          this.state.initialPosition.lat !== this.streetView.getPosition().lat() ||
          this.state.initialPosition.lng !== this.streetView.getPosition().lng()
        )) {
          this.streetView.setPosition(this.state.initialPosition)
        } else {
          this.setState({
            positionSet: true,
            initialPosition: {
              lat: this.streetView.getPosition().lat(),
              lng: this.streetView.getPosition().lng()
            }
          })
        }

        if (this.props.onPositionChanged) {
          this.props.onPositionChanged(this.streetView.getPosition())
        }
      })

      this.streetView.addListener('pov_changed', () => {
        if (this.props.onPovChanged) {
          this.props.onPovChanged(this.streetView.getPov())
        }
      })

      this.streetView.addListener('zoom_changed', () => {
        if (!zoomingAllowed && this.streetView.getZoom() !== zoom) {
          this.streetView.setZoom(zoom)
        }
      })

      if (movingAllowed) {
        const backToStartControlDiv = document.createElement('div')
        new BackToStartControl(backToStartControlDiv, this.streetView, position)

        backToStartControlDiv.index = 1
        this.streetView.controls[googleMaps.ControlPosition.RIGHT_BOTTOM].push(backToStartControlDiv)
      }
    }
  }

  componentDidMount() {
    this.initialize(ReactDOM.findDOMNode(this))
  }

  componentDidUpdate() {
    this.initialize(ReactDOM.findDOMNode(this))
  }

  componentWillUnmount() {
    if (this.streetView) {
      this.props.googleMaps.event.clearInstanceListeners(this.streetView)
    }
  }

  render() {
    return <div
      style={{
        height: `${this.props.height}px`
      }}
    ></div>
  }
}


export default ReactStreetview