import appConf from '../conf/app-conf'

import axios from 'axios'

const generateLocationByArea = () =>
  axios.post(`${appConf.backendUrl}/api/v1/locationgeneration/byarea`)
    .then(res => res.data)

const generateLocationByCountrySelection = countries =>
  axios.post(`${appConf.backendUrl}/api/v1/locationgeneration/bycountryselection`, countries != null ? {
    countries
  } : undefined)
    .then(res => res.data)

const generateUsLocationByStatistic = key =>
  axios.post(`${appConf.backendUrl}/api/v1/locationgeneration/usbystatistic`, {
    key
  })
    .then(res => res.data)


export default {generateLocationByArea, generateLocationByCountrySelection, generateUsLocationByStatistic}