import React from 'react'
import {withRouter} from 'react-router'
import queryString from 'query-string'
import GameService from '../../service/GameService'
import ReactStreetview from '../../react-street-view'
import Card from '@material-ui/core/Card/Card'
import Grid from '@material-ui/core/Grid/Grid'
import Scoreboard from './Scoreboard'
import './Game.scss'
import Button from '@material-ui/core/Button/Button'
import AddLocationIcon from '@material-ui/icons/AddLocation'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import MakeGuessMapView from '../../make-guess-map-view'
import GameStatus from './GameStatus'
import moment from 'moment'
import appConf from '../../conf/app-conf.json'
import MakeCountryGuessMapView from '../../make-country-guess-map-view'
import {mapCountryCodesToNames, mapCountryNamesToCodes} from '../../helper/helperfunctions'

let gameRefetch

const allPlayersReadyForRound = (game, roundNumber) => {
  for (let userId of Object.keys(game.playersToReadyRound)) {
    if (game.playersToReadyRound[userId] < roundNumber) {
      return false
    }
  }
  return true
}

class Game extends React.Component {

  constructor(props) {
    super(props)
    const queryStringData = queryString.parse(window.location.search)
    this.state = {
      gameId: queryStringData.gameId,
      game: {},
      round: {},
      makingGuess: false,
      countdownAudio: null
    }
  }

  componentDidMount() {
    this.loadGame()
  }

  loadGame() {
    if (this.state.gameId) {
      GameService.getGame(this.state.gameId)
        .then(game => {
          if (game.playersToDistances[this.props.user.userId] == null ||
            allPlayersReadyForRound(game, game.playersToDistances[this.props.user.userId].length + 1)) {
            this.setState({game})
            GameService.nextRound(game.gameId)
              .then(round => this.setState({round}))
          } else {
            this.props.history.push(`/roundscore?gameId=${this.state.gameId}`)
          }
        })
      gameRefetch = setInterval(this.fetchGame.bind(this), 1000)
    } else {
      GameService.getGameIdsByFinished(false)
        .then(games => {
          if (games.length > 0) {
            this.setState({
              gameId: games[0]
            }, this.loadGame.bind(this))
            this.props.history.push(`/game?gameId=${games[0]}`)
          }
        })
    }
  }

  componentWillUnmount() {
    clearInterval(gameRefetch)
  }

  fetchGame() {
    GameService.getGame(this.state.gameId).then(game => {
      if (this.state.game.secondsRemaining <= 0) {
        if (this.state.game.gameSettings.gameMode === 'LOCATION') {
          this.makeGuess(this.state.guessLocation ? this.state.guessLocation : {lat: 0, lng: 0})
        } else if (this.state.game.gameSettings.gameMode === 'COUNTRY') {
          let n = this.state.game.gameSettings.countrySelection.countries.length
          this.makeCountryGuess(this.state.game.gameSettings.countrySelection.countries[Math.floor(n * Math.random())])
        }
      } else {
        let countdownAudio = this.state.countdownAudio
        if (this.state.game.secondsRemaining <= 11 && countdownAudio == null) {
          countdownAudio = new Audio(`${appConf.backendUrl}/10sec-timer.mp3`)
          countdownAudio.play()
        }
        this.setState({
          game,
          countdownAudio
        })
      }
    })
  }

  openMakeGuessDialog() {
    this.setState({
      makingGuess: true
    })
  }

  closeMakeGuessDialog() {
    this.setState({
      makingGuess: false
    })
  }

  positionGuess(guessLocation) {
    this.setState({guessLocation})
  }

  countryGuess(guessCountry) {
    this.setState({guessCountry})
  }

  makeGuess(location, retries = 5) {
    if (retries >= 0) {
      GameService.makeGuess(this.state.game.gameId, this.state.round.number, location)
        .then(res => {
          if (res.status === 200 && res.data != null) {
            clearInterval(gameRefetch)
            let countdownAudio = this.state.countdownAudio
            if (countdownAudio != null) {
              countdownAudio.pause()
            }
            this.props.history.push(`/roundscore?gameId=${this.state.gameId}`)
          } else {
            this.makeGuess(location, retries - 1)
          }
        })
        .catch(err => {
          this.makeGuess(location, retries - 1)
        })
    }
  }

  makeCountryGuess(country, retries = 5) {
    if (retries >= 0) {
      GameService.makeCountryGuess(this.state.game.gameId, this.state.round.number, country)
        .then(res => {
          if (res.status === 200 && res.data != null) {
            clearInterval(gameRefetch)
            let countdownAudio = this.state.countdownAudio
            if (countdownAudio != null) {
              countdownAudio.pause()
            }
            this.props.history.push(`/roundscore?gameId=${this.state.gameId}`)
          } else {
            this.makeCountryGuess(country, retries - 1)
          }
        })
        .catch(err => {
          this.makeCountryGuess(country, retries - 1)
        })
    }
  }

  render() {
    return this.props.user && (
      <>
        <Grid
          container
          spacing={24}
        >
          {
            this.state.game.gameId && this.state.round.gameId &&
            <>
              <Dialog
                open={this.state.makingGuess}
                onClose={this.closeMakeGuessDialog.bind(this)}
                maxWidth="xl"
                className="makeGuessDialog"
              >
                <DialogTitle>Make guess</DialogTitle>
                <DialogContent>
                  {this.state.game.gameSettings.gameMode === 'LOCATION' && (
                    <MakeGuessMapView
                      googleMaps={this.props.googleMaps}
                      markerLocation={this.state.guessLocation}
                      setMarker={this.positionGuess.bind(this)}
                      width={650}
                      height={450}
                    />
                  )}
                  {this.state.game.gameSettings.gameMode === 'COUNTRY' && (
                    <MakeCountryGuessMapView
                      googleMaps={this.props.googleMaps}
                      country={mapCountryNamesToCodes([this.state.guessCountry])[0]}
                      setCountry={countryCode => {
                        if (countryCode) {
                          this.countryGuess(mapCountryCodesToNames([countryCode])[0])
                        }
                      }}
                      width={650}
                      height={450}
                      countriesGeoJson={this.props.countriesGeoJson}
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeMakeGuessDialog.bind(this)} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      if (this.state.game.gameSettings.gameMode === 'LOCATION') {
                        this.makeGuess(this.state.guessLocation)
                      } else if (this.state.game.gameSettings.gameMode === 'COUNTRY') {
                        this.makeCountryGuess(this.state.guessCountry)
                      }
                    }}
                    disabled={(this.state.game.gameSettings.gameMode === 'LOCATION' && !this.state.guessLocation) ||
                      (this.state.game.gameSettings.gameMode === 'COUNTRY' && !this.state.guessCountry)}
                    color="primary"
                  >
                    Make guess
                  </Button>
                </DialogActions>
              </Dialog>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={9}
                xl={9}
              >
                <Card>
                  <ReactStreetview
                    googleMaps={this.props.googleMaps}
                    height={750}
                    zoomingAllowed={true}
                    movingAllowed={this.state.game.gameSettings.movingAllowed}
                    withCompass={this.state.game.gameSettings.withCompass}
                    position={this.state.round.location}
                  />
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={3}
                xl={3}
              >
                <div className="gameStatus">
                  <Card>
                    <div className="gameStatusContent">
                      <GameStatus
                        movingAllowed={this.state.game.gameSettings.movingAllowed}
                        withCompass={this.state.game.gameSettings.withCompass}
                        roundNumber={this.state.round.number}
                        totalRounds={this.state.game.gameSettings.numberOfRounds}
                        timeRemaining={this.state.game.secondsRemaining}
                      />
                    </div>
                  </Card>
                </div>
                <div className="gameScoreboard">
                  <Card>
                    <Scoreboard
                      players={this.state.game.players}
                      roundsCompleted={this.state.game.guesses.map(guess => ({
                        player: guess.player,
                        guesses: this.state.game.gameSettings.gameMode === 'LOCATION'
                          ? guess.guesses.slice(0, this.state.round.number - 1).length
                          : guess.guessedCountries.slice(0, this.state.round.number - 1).length
                      }))}
                      playerScores={this.state.round.number > 1 ? Object
                        .entries(this.state.game.playersToScores)
                        .reduce((playerScores, [player, scores]) => [...playerScores, {
                          player,
                          score: scores.slice(0, this.state.round.number - 1).reduce((a, b) => a + b, 0)
                            + (this.state.game.gameSettings.extraPointsForCorrectCountry
                              ? this.state.game.playersToCountryCorrect[player].reduce((extraPoints, correct) => extraPoints + (correct ? 2000 : 0), 0)
                              : 0)
                        }], []) : []}
                      locations={this.state.game.locations}
                    />
                  </Card>
                </div>
                <div className="openMakeGuessButton">
                  <Button variant="contained" color="primary" onClick={this.openMakeGuessDialog.bind(this)}>
                    <AddLocationIcon/>
                    Make guess
                  </Button>
                </div>
              </Grid>
            </>
          }
        </Grid>
      </>
    )
  }
}


export default withRouter(Game)